import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  LinearProgress,
  Box,
  Typography,
  Paper,
  Fade,
  List,
  ListItem,
  SxProps,
  ListItemText,
  Stack,
  Theme,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Alert, AlertTitle } from "@mui/material";

interface ValidatedUploadFieldProps {
  validateFile: (file: File) => Promise<[boolean, string[]]>;
  uploadFile: (file: File) => Promise<void>;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

const ValidatedUploadField: React.FC<ValidatedUploadFieldProps> = ({
  validateFile,
  uploadFile,
  sx,
  disabled = false,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<
    "idle" | "uploading" | "error" | "complete"
  >("idle");

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setIsValidating(true);
      setIsValid(false);
      setValidationErrors([]);
      const [valid, errors] = await validateFile(selectedFile);
      setIsValid(valid);
      setValidationErrors(errors);
      setIsValidating(false);
    }
  };

  const handleUpload = async () => {
    if (file && isValid) {
      setUploadStatus("uploading");
      try {
        await uploadFile(file);
        setUploadStatus("complete");
      } catch (error) {
        setUploadStatus("error");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        position: "relative",
        overflow: "hidden",
        ...sx,
      }}
    >
      {isValidating && (
        <LinearProgress
          sx={{
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      )}
      <Stack gap={2}>
        <Box sx={{}}>
          <input
            accept="*/*"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
            disabled={disabled}
          />
          {file && isValid ? (
            <Button
              variant="contained"
              startIcon={
                uploadStatus === "idle" ? (
                  <CloudUploadIcon />
                ) : uploadStatus === "error" ? (
                  <ReplayIcon />
                ) : uploadStatus === "complete" ? (
                  <CheckCircleIcon />
                ) : undefined
              }
              fullWidth
              disabled={
                uploadStatus === "uploading" ||
                uploadStatus === "complete" ||
                disabled
              }
              onClick={handleUpload}
              sx={{ boxShadow: 3 }}
            >
              {uploadStatus === "uploading" ? (
                <CircularProgress size={24} />
              ) : uploadStatus === "error" ? (
                "Retry"
              ) : uploadStatus === "complete" ? (
                "Complete"
              ) : (
                "Upload"
              )}
            </Button>
          ) : (
            <label htmlFor="raised-button-file" style={{ width: "100%" }}>
              <Button
                variant="outlined"
                component="span"
                startIcon={!isValidating && <CloudUploadIcon />}
                fullWidth
                disabled={isValidating || disabled}
                sx={{ boxShadow: 3 }}
              >
                {isValidating ? <CircularProgress size={24} /> : "Choose File"}
              </Button>
            </label>
          )}
        </Box>

        {file && (
          <Paper
            elevation={1}
            sx={{
              p: 1,
              display: "flex",
              alignItems: "center",
              width: "100%",
              backgroundColor: "background.default",
            }}
          >
            <InsertDriveFileIcon sx={{ mr: 1, color: "text.secondary" }} />
            <Typography variant="body2" noWrap sx={{ flexGrow: 1 }}>
              {file.name}
            </Typography>
            <Fade in={!isValidating && isValid}>
              <CheckCircleOutlineIcon color="success" />
            </Fade>
            <Fade in={!isValidating && !isValid && file !== null}>
              <ErrorOutlineIcon color="error" />
            </Fade>
          </Paper>
        )}
        {validationErrors.length > 0 && (
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              width: "100%",
            }}
          >
            <Alert
              severity="error"
              sx={{
                "& .MuiAlert-message": {
                  width: "100%",
                },
              }}
            >
              <AlertTitle>Validation Errors</AlertTitle>
              <List dense disablePadding>
                {validationErrors.map((error, index) => (
                  <ListItem key={index} disableGutters alignItems="flex-start">
                    <ListItemText
                      primary={error}
                      primaryTypographyProps={{
                        variant: "body2",
                        color: "error",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Alert>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default ValidatedUploadField;
