import { env } from "./env";

export enum EndpointName {
  VALIDATE_CSV = "VALIDATE_CSV",
  UPDATE_FILE = "UPDATE_FILE",
  LIST_BINS = "LIST_BINS",
  UPLOAD_FILE = "UPLOAD_FILE",
  PING_SLACK = "PING_SLACK",
}

export const ApiPath = {
  VALIDATE_CSV: () =>
    `${env.REACT_APP_BACKEND_ENDPOINT}:${env.REACT_APP_PLOTTING_APP_PORT}/plotting/data/validate_csv/`,
  UPDATE_FILE: (path: string) =>
    `${env.REACT_APP_BACKEND_ENDPOINT}:${env.REACT_APP_PLOTTING_APP_PORT}/plotting/data/update/${path}`,
  LIST_BINS: () =>
    `${env.REACT_APP_BACKEND_ENDPOINT}:${env.REACT_APP_PLOTTING_APP_PORT}/plotting/data/bins`,
  UPLOAD_FILE: (binName: string, fileName: string) =>
    `${env.REACT_APP_BACKEND_ENDPOINT}:${env.REACT_APP_PLOTTING_APP_PORT}/plotting/data/upload/${binName}/${fileName}`,
  PING_SLACK: () =>
    `${env.REACT_APP_BACKEND_ENDPOINT}:${env.REACT_APP_PLOTTING_APP_PORT}/message`,
} as const;

export type ApiPathFunction = (typeof ApiPath)[keyof typeof ApiPath];
export type ApiPathValue = ReturnType<ApiPathFunction>;

// Helper function to get the actual URL
export function getApiPath<T extends EndpointName>(
  name: T,
  ...args: Parameters<(typeof ApiPath)[T]>
): ApiPathValue {
  return (ApiPath[name] as any)(...args);
}
