import React from "react";
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { Periodicity } from "../../../../types/plot_requests/plot_request_interfaces";
import { PERIODICITY_INFO } from "../../../../types/plot_requests/plot_request_info";
import { RAMFormControl } from "../../../../../../resources/CustomInputs";

interface BarPlotPanelProps {
  periodicity: Periodicity;
  onChange: (periodicity: Periodicity) => void;
}

const BarPlotPanel: React.FC<BarPlotPanelProps> = ({
  periodicity,
  onChange,
}) => {
  const handleChange = (event: SelectChangeEvent<Periodicity>) => {
    onChange(event.target.value as Periodicity);
  };

  return (
    <Box height="100%" width="100%" padding="10px">
      <RAMFormControl fullWidth size="small">
        <InputLabel>Periodicity</InputLabel>
        <Select value={periodicity} onChange={handleChange} label="Periodicity">
          {Object.entries(PERIODICITY_INFO).map(([value, { name }]) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </RAMFormControl>
    </Box>
  );
};

export default BarPlotPanel;
