import React from "react";
import {
  MenuItem,
  Select,
  InputLabel,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { RAMFormControl } from "../../../../../../resources/CustomInputs";
import { BaseTheme } from "../../../../types/plot_theme/plot_theme_interfaces";
import { AVAILABLE_THEMES } from "../../../../types/plot_theme/available_themes";

interface ThemePanelProps {
  plotTheme: BaseTheme;
  onChange: (partialTheme: Partial<BaseTheme>) => void;
}

const ThemePanel: React.FC<ThemePanelProps> = ({ plotTheme, onChange }) => {
  const [newColor, setNewColor] = React.useState("#000000");
  const [openColorPicker, setOpenColorPicker] = React.useState(false);

  const updatePlotTheme = (themeName: string) => {
    onChange(AVAILABLE_THEMES[themeName]);
  };

  const addColorToTheme = (color: string) => {
    onChange({
      colors: {
        ...plotTheme.colors,
        palette: [...plotTheme.colors.palette, color],
      },
    });
  };

  const handleClickOpen = () => {
    setOpenColorPicker(true);
  };

  const handleClose = () => {
    setOpenColorPicker(false);
  };

  const handleAddColor = () => {
    addColorToTheme(newColor);
    handleClose();
  };

  return (
    <Box padding="5px" paddingTop="10px">
      <RAMFormControl size="small" fullWidth>
        <InputLabel>Plot Theme</InputLabel>
        <Select
          value={plotTheme.theme_name}
          label="Plot Theme"
          onChange={(event) => {
            const value = event.target.value as string;
            updatePlotTheme(value);
          }}
        >
          {Object.keys(AVAILABLE_THEMES).map((themeName) => (
            <MenuItem key={themeName} value={themeName}>
              {themeName}
            </MenuItem>
          ))}
        </Select>
      </RAMFormControl>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        {plotTheme.colors.palette.map((color, index) => (
          <div
            key={index}
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: color,
              border: color === "#000000" ? "1px solid #ffffff" : "none",
            }}
          />
        ))}
      </div>
      <Button onClick={handleClickOpen} style={{ marginTop: "10px" }}>
        Add Color
      </Button>
      <Dialog open={openColorPicker} onClose={handleClose}>
        <DialogTitle>Add a Color</DialogTitle>
        <DialogContent>
          <input
            type="color"
            value={newColor}
            onChange={(e) => setNewColor(e.target.value)}
            style={{ width: "100%", height: "50px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddColor}>Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ThemePanel;
