import React from "react";
import { useUserReport } from "../common/contexts/UserReportContext";
import { Button } from "@mui/material";

const TestUserReportContext: React.FC = () => {
  const { openBugReport, openFeatureRequest, openErrorPrompt } =
    useUserReport();

  return (
    <>
      <Button onClick={() => openBugReport()}>Report a Bug</Button>
      <Button onClick={() => openFeatureRequest()}>Request a Feature</Button>
      <Button
        onClick={() =>
          openErrorPrompt("ERROR WARNING MESSAGE", "SLACK MESSAGE")
        }
      >
        Error Prompt
      </Button>
    </>
  );
};

export default TestUserReportContext;
