import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { JustifyField } from "../apps/plotting/components/panel_drawer/panels/SettingsPanel/SettingsFields";
import { Justify } from "../apps/plotting/types/plot_settings/plot_settings_interfaces";

const TestJustifyField: React.FC = () => {
  const [justification, setJustification] = useState<Justify>("left");

  const handleJustificationChange = (newValue: Justify) => {
    setJustification(newValue);
  };

  return (
    <Box sx={{ p: 2, maxWidth: 300 }}>
      <Typography variant="h6" gutterBottom>
        Test Justify Field
      </Typography>
      <JustifyField
        label="Text Alignment"
        value={justification}
        onChange={handleJustificationChange}
      />
      <Typography sx={{ mt: 2 }}>
        Current justification: {justification}
      </Typography>
    </Box>
  );
};

export default TestJustifyField;
