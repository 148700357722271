import React, {
  createContext,
  useContext,
  useState,
  ReactElement,
  ReactNode,
} from "react";

interface DialogContextProps {
  dialogOpen: boolean;
  dialogContent: ReactElement | null;
  dialogName: string | null;
  openDialog: (content: ReactElement, name: string) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

interface DialogProviderProps {
  children: ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<ReactElement | null>(null);
  const [dialogName, setDialogName] = useState<string | null>(null);

  const openDialog = (content: ReactElement, name: string) => {
    setDialogContent(content);
    setDialogName(name);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogContent(null);
    setDialogName(null);
  };

  return (
    <DialogContext.Provider
      value={{ dialogOpen, dialogContent, dialogName, openDialog, closeDialog }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialogContext must be used within a DialogProvider");
  }
  return context;
};
