import { EndpointName } from "../../config/apiPaths";
import { ContentType, makeRequestV2, ResponseDataV2 } from "./requestsV2";

export async function pingSlack(
  message: string,
  token: string,
): Promise<ResponseDataV2> {
  const body = JSON.stringify({ text: message });
  const response = await makeRequestV2(
    EndpointName.PING_SLACK,
    token,
    body,
    "POST",
    ContentType.JSON,
  );
  return response;
}
