import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ResponsiveAppBar from "../common/components/ResponsiveAppBar";
import AppIcon from "../common/components/AppIcon";
import Box from "@mui/material/Box";
import { appConfigs } from "../app_configs";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { useTheme } from "@mui/material";
import particlesOptions from "../resources/particlesOptions";

const AppsHomeScreen = () => {
  const theme = useTheme()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userScopes, setUserScopes] = useState([]);
  const [init, setInit] = useState(false);


  useEffect(() => {
    const getUserScopes = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          setUserScopes(decodedToken.permissions || []);
        } catch (error) {
          console.error("Error getting user permissions:", error);
        }
      }
    };

    getUserScopes();
  }, [isAuthenticated, getAccessTokenSilently]);

  
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesConfig = particlesOptions({
    backgroundColor: theme.palette.background.default,
    primaryColor: theme.palette.primary.main,
  })
 
  const hasRequiredScopes = (requiredScopes) => {
    if (!requiredScopes || requiredScopes.length === 0) return true;
    return requiredScopes.every(scope => userScopes.includes(scope));
  };

  return (
    <Box sx={{ position: 'relative', height: "100vh", overflow: 'hidden' }}>
      {init && (
        <Particles
          id="tsparticles"
          options={particlesConfig}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        />
      )}
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <ResponsiveAppBar
          activePage={"Home"}
          styles={{ backgroundColor: "transparent" }}
        />
        <Box paddingTop="100px" display="flex" maxWidth="80rem" margin="auto">
          {appConfigs.map((app) => (
            <AppIcon
              key={app.name}
              path={app.path}
              AppName={app.name}
              Icon={app.icon}
              disabled={!hasRequiredScopes(app.requiredScopes)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AppsHomeScreen;