import PodcastsIcon from "@mui/icons-material/Podcasts";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PodcastPostScreen from "./apps/podcast_post/screens/PodcastPostScreen";
import PlottingScreen from "./apps/plotting/screens/PlottingScreen";

export const appConfigs = [
  {
    name: "Podcast Post",
    path: "podcast_post",
    icon: PodcastsIcon,
    requiredScopes: ["all:podcast"],
    component: PodcastPostScreen
  },
  {
    name: "Plotting",
    path: "plotting",
    icon: QueryStatsIcon,
    requiredScopes: ["all:plotting"],
    component: PlottingScreen
  },
];