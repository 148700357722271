import { PlotRequest } from "../types/plot_requests/plot_request_interfaces";

export const isValidPlotConfig = (config: PlotRequest): boolean => {
  const baseValidation =
    config.data_config.portfolios.length > 0 &&
    config.data_config.portfolios.every(
      (portfolio) =>
        portfolio.files.length > 0 &&
        portfolio.files.every((file) => file.path),
    );

  switch (config.plot_type) {
    case "line_plot":
      return baseValidation;
    case "bar_plot":
      return baseValidation;
    case "correl_plot":
      return baseValidation;
    default:
      return false;
  }
};
