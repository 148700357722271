import React from "react";
import { Checkbox, Typography, Grid } from "@mui/material/";
import FormControlLabel from "@mui/material/FormControlLabel";
import { snakeCaseToTitleCase } from "../../../../../../../common/utils/textFormatting";
import { MetricMethods } from "../../../../../types/plot_requests/performance_table";

interface PerformanceMetricsProps {
  onChange: (selectedMetrics: MetricMethods[]) => void;
  metrics: MetricMethods[];
}
const PerformanceMetrics: React.FC<PerformanceMetricsProps> = (
  props: PerformanceMetricsProps,
) => {
  const handleCheck = (option: MetricMethods, checked: boolean) => {
    let newSelected = [...props.metrics];
    if (checked) {
      if (!newSelected.includes(option)) {
        newSelected.push(option);
      }
    } else {
      newSelected = newSelected.filter((item) => item !== option);
    }
    props.onChange(newSelected);
  };

  // function to render checkboxes
  const checkboxes = Object.values(MetricMethods).map((option) => (
    <Grid item xs={6} key={option}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={props.metrics.includes(option)}
            onChange={(event, checked) => handleCheck(option, checked)}
            name={option}
          />
        }
        label={
          <Typography style={{ fontSize: "0.8rem" }}>
            {snakeCaseToTitleCase(option)}
          </Typography>
        }
      />
    </Grid>
  ));

  return (
    <div>
      <Grid container spacing={1} style={{ marginBottom: "20px" }}>
        {checkboxes}
      </Grid>
    </div>
  );
};

export default PerformanceMetrics;
