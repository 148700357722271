import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";

const CustomAccordion = styled(MuiAccordion)(({ theme }) => ({
  "& .MuiAccordionSummary-root": {
    backgroundColor: theme.palette.background.paper,
    minHeight: "40px",
    padding: "0 10px",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiAccordionDetails-root": {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.primary.main,
  },
  "& svg": {
    fontSize: "1.1rem",
  },
}));
export const Accordion = CustomAccordion;
export default Accordion;
