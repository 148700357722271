import React from "react";
import ValidatedUploadField from "../apps/plotting/components/common/ValidatedUploadField";
import { useAuth0 } from "@auth0/auth0-react";
import { validateFile, updateFile } from "../apps/plotting/utils/requestUtils";

const TestValidatedUploadField: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();

  const handleFileValidation = async (
    file: File,
  ): Promise<[boolean, string[]]> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await validateFile(file, token);

      if (response.isSuccess) {
        return [true, []];
      } else {
        // Check if the error is a validation error (status 422)
        if (response.body.status === 422) {
          const responseBody = await response.body.json();
          const errors = responseBody.detail.errors;
          return [false, errors];
        } else {
          return [
            false,
            [`An unknown error occurred: ${response.body.status}`],
          ];
        }
      }
    } catch (error) {
      console.error("File validation error:", error);
      return [false, ["An error occurred during file validation."]];
    }
  };

  const path = "/resolve_data/plotting/files/dropbox/AAXJ.csv"; // for testing
  const handleFileUpdate = async (file: File): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await updateFile(path, file, token);

      if (!response.isSuccess) {
        alert("An error occured when uploading the file.");
      }
    } catch (error) {
      alert("An error occured when uploading the file.");
    }
  };

  return (
    <ValidatedUploadField
      validateFile={handleFileValidation}
      uploadFile={handleFileUpdate}
      sx={{
        width: 300,
      }}
    />
  );
};

export default TestValidatedUploadField;
