import {
  LinePlotRequest,
  CorrelPlotRequest,
  BarPlotRequest,
  PlotRequest,
  PlotType,
} from "./plot_request_interfaces";
import {
  defaultLinePlotSettings,
  defaultCorrelPlotSettings,
  defaultBarPlotSettings,
} from "../plot_settings/default_plot_settings";
import { resolveTheme } from "../plot_theme/available_themes";
import { defaultDataConfig } from "../data_config/default_data_config";
import { PLOT_STYLE_INFO } from "../plot_style/plot_style_info";

export const defaultLinePlotRequest: LinePlotRequest = {
  plot_type: "line_plot",
  settings: {
    ...defaultLinePlotSettings,
    ...(PLOT_STYLE_INFO.line_plot?.investment_performance?.settings || {}),
  },
  theme: resolveTheme,
  data_config: {
    ...defaultDataConfig,
    transform: {
      transform: "investment_performance",
      kwargs: { start_val: 100 },
    },
  },
  style: "investment_performance",
};
export const defaultCorrelPlotRequest: CorrelPlotRequest = {
  plot_type: "correl_plot",
  settings: defaultCorrelPlotSettings,
  theme: resolveTheme,
  data_config: defaultDataConfig,
  style: undefined,
};

export const defaultBarPlotRequest: BarPlotRequest = {
  plot_type: "bar_plot",
  settings: defaultBarPlotSettings,
  theme: resolveTheme,
  data_config: defaultDataConfig,
  periodicity: "Years",
  style: undefined,
};

export const DEFAULT_PLOT_REQUESTS: Record<PlotType, PlotRequest> = {
  line_plot: defaultLinePlotRequest,
  bar_plot: defaultBarPlotRequest,
  correl_plot: defaultCorrelPlotRequest,
};

export const STARTUP_PLOT_TYPE = defaultLinePlotRequest;
