import React from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DataConfig,
  Portfolio,
} from "../../../../types/data_config/data_config_interfaces";
import PortfolioItem from "./PortfolioItem";
import Accordion from "../../../../../../resources/Accordion";

interface DataConfigPanelProps {
  dataConfig: DataConfig;
  onChange: (newDataConfig: DataConfig) => void;
}

const DataConfigPanel: React.FC<DataConfigPanelProps> = ({
  dataConfig,
  onChange,
}) => {
  const handleAddPortfolio = () => {
    const newPortfolio: Portfolio = {
      files: [],
      name: `Portfolio ${dataConfig.portfolios.length + 1}`,
      weights: [],
    };
    onChange({
      ...dataConfig,
      portfolios: [...dataConfig.portfolios, newPortfolio],
    });
  };

  const handlePortfolioChange = (
    index: number,
    updatedPortfolio: Portfolio,
  ) => {
    const newPortfolios = [...dataConfig.portfolios];
    newPortfolios[index] = updatedPortfolio;
    onChange({
      ...dataConfig,
      portfolios: newPortfolios,
    });
  };

  const handlePortfolioDelete = (index: number) => {
    const newPortfolios = dataConfig.portfolios.filter((_, i) => i !== index);
    onChange({
      ...dataConfig,
      portfolios: newPortfolios,
    });
  };

  return (
    <Box height="100%" width="100%">
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 0.5, py: 1 }}
        >
          <Typography
            variant="button"
            sx={{
              fontWeight: 800,
              fontSize: 13,
              width: "fit-content",
              color: "#c6c6c6",
            }}
          >
            DATA CONFIGURATION
          </Typography>
        </Box>
        <Divider />
        {dataConfig.portfolios.map((portfolio, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`portfolio-${index}-content`}
              id={`portfolio-${index}-header`}
            >
              <Typography>{portfolio.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PortfolioItem
                portfolio={portfolio}
                onChange={(updatedPortfolio) =>
                  handlePortfolioChange(index, updatedPortfolio)
                }
                onDelete={() => handlePortfolioDelete(index)}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginTop="5px"
        sx={{ px: 2 }}
      >
        <Button
          fullWidth
          color="inherit"
          endIcon={<AddIcon fontSize="small" />}
          onClick={handleAddPortfolio}
        >
          <Typography sx={{ fontWeight: 800, fontSize: 13, color: "#c6c6c6" }}>
            Add Portfolio
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default DataConfigPanel;
