// Base Plot Settings
export type Justify = "left" | "center" | "right";
export type AxisTickAngle = "0" | "45" | "90";
export type LegendPosition = "top" | "bottom" | "left" | "right";
export type AnnotationColor = "white" | "black" | "auto";

export interface BaseSettings {
  title: string | null;
  title_fontsize: number;
  title_justify: Justify;

  subtitle: string | null;
  subtitle_fontsize: number;
  subtitle_justify: Justify;

  caption: string | null;
  caption_fontsize: number;
  caption_justify: Justify;

  x_label: string | null;
  x_label_fontsize: number;
  x_axis_tick_angle: AxisTickAngle;
  x_axis_tick_format: string | null;
  x_axis_tick_fontsize: number;

  y_label: string | null;
  y_label_fontsize: number;
  y_axis_tick_angle: AxisTickAngle;
  y_axis_tick_format: string | null;
  y_axis_tick_fontsize: number;

  axis_thickness: number;
  axis_color: string;

  figsize: [number, number];
  panel_border: boolean;
  show_panel_grid: boolean;

  show_legend: boolean;
  legend_position: LegendPosition;
  legend_title: string | null;
  legend_fontsize: number;
}

export interface BaseMatplotSettings extends BaseSettings {
  dpi: number;
}

// Line Plot Settings
export enum HorizontalLineType {
  SOLID = "solid",
  DASHED = "dashed",
  DOTTED = "dotted",
  DOTDASH = "dotdash",
  LONGDASH = "longdash",
  TWODASH = "twodash",
}
export interface LinePlotSettings extends BaseSettings {
  scale_y_log2: boolean;
  show_date_range: boolean;
  place_horizontal_line_at?: number;
  horizontal_line_type: HorizontalLineType;
  horizontal_line_color: string;
  horizontal_line_size: number;
}

// Bar Plot Settings
export interface BarPlotSettings extends BaseSettings {
  show_annotations: boolean;
  annotations_format: string;
}

// Waterfall Plot Settings
export interface WaterfallPlotSettings extends BaseSettings {
  sorted_value: boolean;
  threshold: number | null;
  max_values: number | null;
  calc_total: boolean;
  total_title: string | null;
}

// Correlation Plot Settings
export interface CorrelPlotSettings extends BaseMatplotSettings {
  show_annotation: boolean;
  annotation_fmt: string;
  annotation_fontsize: number;
  annotation_decimals: number;
  square: boolean;
  linewidths: number;
  mask_upper: boolean;
  annotation_color: AnnotationColor;
  show_legend: boolean;
  show_panel_grid: boolean;
}

export type PlotSettings =
  | LinePlotSettings
  | BarPlotSettings
  | CorrelPlotSettings
  | BaseSettings
  | BarPlotSettings;
