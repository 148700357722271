export const processHTML = (
  html: string,
  target: React.RefObject<HTMLDivElement>,
) => {
  // Check if target exists
  if (!target.current) {
    console.error("Target element is not available");
    return;
  }

  // Create a temporary container
  const temp = document.createElement("div");
  temp.innerHTML = html;

  // Append non-script elements
  Array.from(temp.children).forEach((child) => {
    if (child.tagName !== "SCRIPT") {
      target.current!.appendChild(child);
    }
  });

  // Function to load and execute a single script
  const loadAndExecuteScript = (script: HTMLScriptElement): Promise<void> => {
    return new Promise((resolve) => {
      const newScript = document.createElement("script");
      Array.from(script.attributes).forEach((attr) =>
        newScript.setAttribute(attr.name, attr.value),
      );
      newScript.textContent = script.textContent;
      newScript.onload = () => resolve();
      newScript.onerror = () => {
        console.error(`Error loading script: ${script.src || "inline script"}`);
        resolve(); // Resolve even on error to continue with other scripts
      };
      document.head.appendChild(newScript);
    });
  };

  // Execute scripts in order
  const scripts = Array.from(temp.getElementsByTagName("script"));
  scripts
    .reduce((promise, script) => {
      return promise.then(() => loadAndExecuteScript(script));
    }, Promise.resolve())
    .then(() => {
      console.log("All scripts loaded and executed");
    })
    .catch((error) => {
      console.error("Error in script execution:", error);
    });
};
