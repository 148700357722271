import React, { Children, useState, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, Stack, Tooltip, IconButton } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

const DEFAULT_DRAWER_WIDTH = 400;

interface PanelDrawerProps {
  children: React.ReactNode;
}

interface PanelProps {
  name: string;
  icon?: SvgIconComponent;
  children: React.ReactNode;
}

export const Panel: React.FC<PanelProps> = ({ children }) => {
  return <>{children}</>;
};

export default function PanelDrawer({ children }: PanelDrawerProps) {
  const theme = useTheme();
  const [activeDrawerIndex, setActiveDrawerIndex] = useState(0);
  const [width, setWidth] = useState(DEFAULT_DRAWER_WIDTH);
  const [open, setOpen] = useState<boolean>(false);
  const [isResizing, setIsResizing] = useState(false);

  const handlePanelSelect = (index: number) => {
    if (index === activeDrawerIndex) {
      setOpen(!open);
    } else {
      setOpen(true);
      setActiveDrawerIndex(index);
    }
  };

  const startResizing = useCallback(
    (mouseDownEvent: React.MouseEvent<HTMLDivElement>) => {
      setIsResizing(true);
      mouseDownEvent.preventDefault();
      let startWidth = width;
      const startPositionX = mouseDownEvent.clientX;

      const dragger = document.getElementById("dragger");
      if (dragger) {
        dragger.style.width = "100vw";
        dragger.style.position = "fixed";
        dragger.style.zIndex = "1500";
      }

      const handleMouseMove = (mouseMoveEvent: MouseEvent) => {
        mouseMoveEvent.preventDefault();
        const currentWidth =
          startWidth + (mouseMoveEvent.clientX - startPositionX);
        setWidth(Math.max(240, currentWidth));
      };

      const handleMouseUp = () => {
        setIsResizing(false);
        if (dragger) {
          dragger.style.width = "5px";
          dragger.style.zIndex = "1500";
          dragger.style.position = "absolute";
        }
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [width]
  );

  const panels = Children.toArray(children) as React.ReactElement<PanelProps>[];

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        backgroundColor: "background.paper",
      }}
    >
      {/* Always visible icons */}
      <Box
        width="50px"
        display="flex"
        flexDirection="column"
        borderRight={1}
        borderColor="divider"
      >
        <Stack spacing={1}>
          {panels.map((panel, index) => (
            <Tooltip title={panel.props.name} key={index} placement="right">
              <IconButton
                onClick={() => {
                  handlePanelSelect(index);
                }}
                color={activeDrawerIndex === index ? "primary" : "default"}
              >
                {panel.props.icon && (
                  <panel.props.icon sx={{ fontSize: "20px" }} />
                )}
              </IconButton>
            </Tooltip>
          ))}
        </Stack>
      </Box>

      {/* Drawer */}
      <Box
        sx={{
          width: open ? width : 0,
          transition: isResizing
            ? "none"
            : theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
        }}
      >
        <Drawer
          sx={{
            height: "100%",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              position: "relative",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
            {panels[activeDrawerIndex]}
        </Drawer>
      </Box>

      {/* Resizer */}
      {open && (
        <Box
          id="dragger"
          sx={{
            width: "5px",
            cursor: "ew-resize",
            position: "absolute",
            left: width + 50, // Add 30px for the icon bar
            top: 0,
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: 1500,
          }}
          onMouseDown={startResizing}
        />
      )}
    </Box>
  );
}
