import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DEFAULT_DISCLAIMERS,
  DEFAULT_MAIN_DISCLAIMER,
} from "../../resources/disclaimers";

interface Disclaimer {
  name: string;
  content: string;
}

interface DisclaimerSectionProps {
  disclaimers?: Record<string, Disclaimer>;
  mainDisclaimer?: string;
  variant?: "accordion" | "dialog";
}

const DisclaimerSection: React.FC<DisclaimerSectionProps> = ({
  disclaimers = DEFAULT_DISCLAIMERS,
  mainDisclaimer = DEFAULT_MAIN_DISCLAIMER,
  variant = "accordion",
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [openDialog, setOpenDialog] = useState<string | null>(null);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleOpenDialog = (dialogType: string) => {
    setOpenDialog(dialogType);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  const renderAccordionVersion = () => (
    <Box sx={{ bgcolor: "background.paper", p: 1 }}>
      <Typography sx={{ fontSize: 10, color: "#ffffff", mb: 1 }}>
        {mainDisclaimer}
      </Typography>
      {Object.entries(disclaimers).map(([key, value]) => (
        <Accordion
          key={key}
          expanded={expanded === key}
          onChange={handleAccordionChange(key)}
          sx={{
            bgcolor: "background.paper",
            color: "#ffffff",
            boxShadow: "none",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
            sx={{
              minHeight: "auto",
              "& .MuiAccordionSummary-content": {
                margin: "4px 0",
              },
            }}
          >
            <Typography sx={{ fontSize: 10, fontWeight: "bold" }}>
              {value.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0 }}>
            <Typography sx={{ fontSize: 10 }}>{value.content}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );

  const renderDialogVersion = () => (
    <>
      <Box
        sx={{
          position: "relative",
          height: "auto",
          bgcolor: "background.paper",
          px: 1,
          py: 0.5,
        }}
      >
        <Typography sx={{ lineHeight: 1.25, fontSize: 10, color: "#ffffff" }}>
          {mainDisclaimer}
          {" - "}
          {Object.entries(disclaimers).map(([key, value], index) => (
            <React.Fragment key={key}>
              <b
                onClick={() => handleOpenDialog(key)}
                style={{ cursor: "pointer" }}
              >
                {value.name}
              </b>
              {index < Object.entries(disclaimers).length - 1 && " - "}
            </React.Fragment>
          ))}
        </Typography>
      </Box>

      <Dialog
        open={openDialog !== null}
        onClose={handleCloseDialog}
        aria-labelledby="disclaimer-dialog-title"
        aria-describedby="disclaimer-dialog-description"
      >
        {openDialog && (
          <>
            <DialogTitle id="disclaimer-dialog-title">
              {disclaimers[openDialog as keyof typeof disclaimers].name}
            </DialogTitle>
            <DialogContent>
              <Typography id="disclaimer-dialog-description">
                {disclaimers[openDialog as keyof typeof disclaimers].content}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );

  return variant === "accordion"
    ? renderAccordionVersion()
    : renderDialogVersion();
};

export default DisclaimerSection;
