import { DataConfig } from "../data_config/data_config_interfaces";
import { defaultDataConfig } from "../data_config/default_data_config";

export interface PerformanceTableSettings {
  row_height?: number;
  header_color?: string;
  table_width?: number;
  font_family?: string;
  font_size: number;
  alternate_row_color: string;
  cell_padding: number;
  first_column_align: string;
  other_columns_align: string;
}

export enum MetricMethods {
  INCEPTION_DATE = "Inception date",
  ANNUALIZED_RETURN = "Annualized return",
  ANNUALIZED_VOLATILITY = "Annualized volatility",
  ANNUALIZED_SHARPE = "Annualized sharpe",
  ANNUALIZED_SORTINO = "Annualized sortino",
  ROLLING_DRAWDOWNS = "Maximum drawdown",
  AVERAGE_DRAWDOWN = "Average drawdown",
  BEST_DAY = "Best day",
  WORST_DAY = "Worst day",
  BEST_MONTH = "Best month",
  WORST_MONTH = "Worst month",
  BEST_YEAR = "Best year",
  WORST_YEAR = "Worst year",
}

export interface PerformanceTableRequest {
  plot_type: "perf_table";
  settings: PerformanceTableSettings;
  metrics: MetricMethods[];
  use_excess_returns: string[];
  data_config: DataConfig;
}

const defaultPerformanceTableSettings: PerformanceTableSettings = {
  font_size: 12,
  alternate_row_color: "#f2f2f2",
  cell_padding: 5,
  first_column_align: "left",
  other_columns_align: "right",
};

export const defaultPerformanceTableRequest: PerformanceTableRequest = {
  plot_type: "perf_table",
  settings: defaultPerformanceTableSettings,
  metrics: [
    MetricMethods.INCEPTION_DATE,
    MetricMethods.ANNUALIZED_RETURN,
    MetricMethods.ANNUALIZED_VOLATILITY,
    MetricMethods.ANNUALIZED_SHARPE,
    MetricMethods.ANNUALIZED_SORTINO,
    MetricMethods.ROLLING_DRAWDOWNS,
    MetricMethods.AVERAGE_DRAWDOWN,
  ],
  use_excess_returns: [],
  data_config: defaultDataConfig,
};
