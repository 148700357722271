import { env } from "../../config/env";
import { EndpointName, getApiPath, ApiPath } from "../../config/apiPaths";

export interface ResponseDataV2 {
  isSuccess: boolean;
  body: Response;
}

export enum ContentType {
  JSON = "application/json",
  MULTIPART = "multipart/form-data",
}

export async function makeRequestV2<T extends EndpointName>(
  pathName: T,
  authToken: string,
  body: BodyInit | null,
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" = "GET",
  contentType?: ContentType | string,
  additionalHeaders: Record<string, string> = {},
  ...pathArgs: Parameters<(typeof ApiPath)[T]>
): Promise<ResponseDataV2> {
  try {
    const headers: HeadersInit = {
      Authorization: `Bearer ${authToken}`,
      ...additionalHeaders,
    };

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    let url = getApiPath(pathName, ...pathArgs);
    const response = await fetch(url, {
      method,
      headers,
      body: body,
    });

    if (!response.ok) {
      return {
        isSuccess: false,
        body: response,
      };
    }

    return {
      isSuccess: true,
      body: response,
    };
  } catch (error) {
    const errorResponse = new Response(
      JSON.stringify({ message: "Network Error" }),
      {
        status: 503,
        statusText: "Service Unavailable",
        headers: { "Content-Type": "application/json" },
      },
    );

    return {
      isSuccess: false,
      body: errorResponse,
    };
  }
}
