import React from "react";
import { Box, Stack, Button, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { RAMTextField } from "../../../../../../resources/CustomInputs";
import AssetInput from "../../../common/AssetInput";
import {
  Portfolio,
  File,
} from "../../../../types/data_config/data_config_interfaces";
import { useDataContext } from "../../../../contexts/DataContext";

interface PortfolioItemProps {
  portfolio: Portfolio;
  onChange: (updatedPortfolio: Portfolio) => void;
  onDelete?: () => void;
  showName?: boolean;
}

const PortfolioItem: React.FC<PortfolioItemProps> = ({
  portfolio,
  onChange,
  onDelete = null,
  showName = true,
}) => {
  const { filesMetadata } = useDataContext();

  const handleAddFile = () => {
    const updatedFiles = [...portfolio.files, { path: "" }];
    const updatedWeights = [...portfolio.weights, 1];
    onChange({ ...portfolio, files: updatedFiles, weights: updatedWeights });
  };

  const handleUpdateFileRequest = (index: number, newValue: string) => {
    const metadata = filesMetadata[newValue];
    if (metadata) {
      const updatedFile: File = { path: newValue };
      const updatedFiles = portfolio.files.map((f, idx) =>
        idx === index ? updatedFile : f,
      );
      onChange({ ...portfolio, files: updatedFiles });
    }
  };

  const handleUpdateWeight = (index: number, newWeight: number) => {
    const updatedWeights = portfolio.weights.map((w, idx) =>
      idx === index ? newWeight / 100 : w,
    );
    onChange({ ...portfolio, weights: updatedWeights });
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = portfolio.files.filter((_, idx) => idx !== index);
    const updatedWeights = portfolio.weights.filter((_, idx) => idx !== index);
    onChange({ ...portfolio, files: updatedFiles, weights: updatedWeights });
  };

  const handleNameChange = (newName: string) => {
    onChange({ ...portfolio, name: newName });
  };

  return (
    <Stack sx={{ width: "100%", p: 0.5 }} gap={1}>
      {(showName || onDelete) && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="10px"
        >
          {showName && (
            <RAMTextField
              sx={{ minWidth: "150px" }}
              label="Portfolio Name"
              defaultValue={portfolio.name}
              onBlur={(event) => handleNameChange(event.target.value)}
              size="small"
            />
          )}
          {onDelete && (
            <IconButton sx={{ p: 0 }} onClick={onDelete}>
              <DeleteIcon sx={{ fontSize: "15pt" }} />
            </IconButton>
          )}
        </Box>
      )}

      <Divider />

      {portfolio.files.map((file, index) => (
        <Box key={index} display="flex" alignItems="center" gap="10px">
          <AssetInput
            onChange={(value) =>
              handleUpdateFileRequest(index, value as string)
            }
            value={file.path}
            label="Asset"
            multiple={false}
          />
          <RAMTextField
            required
            type="number"
            sx={{ maxWidth: "80px" }}
            label="Weight (%)"
            defaultValue={portfolio.weights[index] * 100}
            onBlur={(event) =>
              handleUpdateWeight(index, parseFloat(event.target.value))
            }
            size="small"
          />
          <IconButton
            onClick={() => handleRemoveFile(index)}
            size="small"
            sx={{ p: 0 }}
          >
            <CloseIcon sx={{ fontSize: "15pt" }} />
          </IconButton>
        </Box>
      ))}

      <Button
        fullWidth
        startIcon={<AddIcon />}
        onClick={handleAddFile}
        variant="outlined"
        size="small"
        sx={{ alignSelf: "flex-start", mt: 1 }}
      >
        Add Asset
      </Button>
    </Stack>
  );
};

export default PortfolioItem;
