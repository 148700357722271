import {
  StringField,
  NumberField,
  BooleanField,
  SelectField,
  ArrayField,
  LabelSettingsField,
} from "../../components/panel_drawer/panels/SettingsPanel/SettingsFields";
import {
  AxisTickAngle,
  LegendPosition,
  AnnotationColor,
  PlotSettings,
} from "./plot_settings_interfaces";

export const AXIS_TICK_ANGLE_OPTIONS: Record<AxisTickAngle, string> = {
  "0": "0°",
  "45": "45°",
  "90": "90°",
};

export const LEGEND_POSITION_OPTIONS: Record<LegendPosition, string> = {
  top: "Top",
  bottom: "Bottom",
  left: "Left",
  right: "Right",
};

export const ANNOTATION_COLOR_OPTIONS: Record<AnnotationColor, string> = {
  white: "White",
  black: "Black",
  auto: "Auto",
};

export type SettingGroup =
  | "General"
  | "Axes"
  | "Legend"
  | "Line Plot"
  | "Waterfall Plot"
  | "Correlation Plot";

export type CompositeField = {
  type: "composite";
  name: string;
  args: Record<string, any>;
  fields: { [name: string]: keyof PlotSettings };
  component: React.ComponentType<any>;
};

export type SettingInfo =
  | {
      name: string;
      component: React.ComponentType<any>;
      args?: Record<string, any>;
      group: SettingGroup;
    }
  | CompositeField;

export const PLOT_SETTINGS_INFO: Record<
  keyof PlotSettings | string,
  SettingInfo
> = {
  // Base Settings
  titleSettings: {
    type: "composite",
    name: "Title Settings",
    fields: {
      text: "title",
      fontSize: "title_fontsize",
      justify: "title_justify",
    },
    component: LabelSettingsField,
    group: "General",
    args: { titleLabel: "Title" },
  },
  subtitleSettings: {
    type: "composite",
    name: "Subtitle Settings",
    fields: {
      text: "subtitle",
      fontSize: "subtitle_fontsize",
      justify: "subtitle_justify",
    },
    component: LabelSettingsField,
    group: "General",
    args: { titleLabel: "Subtitle" },
  },
  captionSettings: {
    type: "composite",
    name: "Caption Settings",
    fields: {
      text: "caption",
      fontSize: "caption_fontsize",
      justify: "caption_justify",
    },
    component: LabelSettingsField,
    group: "General",
    args: { titleLabel: "Caption" },
  },
  x_label: { name: "X-Axis Label", component: StringField, group: "Axes" },
  x_label_fontsize: {
    name: "X-Axis Label Font Size",
    component: NumberField,
    group: "Axes",
  },
  x_axis_tick_angle: {
    name: "X-Axis Tick Angle",
    component: SelectField,
    args: { options: AXIS_TICK_ANGLE_OPTIONS },
    group: "Axes",
  },
  x_axis_tick_format: {
    name: "X-Axis Tick Format",
    component: StringField,
    group: "Axes",
  },
  y_label: { name: "Y-Axis Label", component: StringField, group: "Axes" },
  y_label_fontsize: {
    name: "Y-Axis Label Font Size",
    component: NumberField,
    group: "Axes",
  },
  y_axis_tick_angle: {
    name: "Y-Axis Tick Angle",
    component: SelectField,
    args: { options: AXIS_TICK_ANGLE_OPTIONS },
    group: "Axes",
  },
  x_axis_tick_fontsize: {
    name: "X-Axis Tick Font Size",
    component: NumberField,
    group: "Axes",
  },
  y_axis_tick_fontsize: {
    name: "Y-Axis Tick Font Size",
    component: NumberField,
    group: "Axes",
  },
  show_annotations: {
    name: "Bar Annotations",
    component: BooleanField,
    group: "General",
  },
  axis_thickness: {
    name: "Axis Thickness",
    component: NumberField,
    group: "Axes",
  },
  axis_color: { name: "Axis Color", component: StringField, group: "Axes" },
  figsize: { name: "Figure Size", component: ArrayField, group: "General" },
  panel_border: {
    name: "Show Panel Border",
    component: BooleanField,
    group: "General",
  },
  show_panel_grid: {
    name: "Show Panel Grid",
    component: BooleanField,
    group: "General",
  },
  show_legend: {
    name: "Show Legend",
    component: BooleanField,
    group: "Legend",
  },
  legend_position: {
    name: "Legend Position",
    component: SelectField,
    args: { options: LEGEND_POSITION_OPTIONS },
    group: "Legend",
  },
  legend_title: {
    name: "Legend Title",
    component: StringField,
    group: "Legend",
  },
  legend_fontsize: {
    name: "Legend Font Size",
    component: NumberField,
    group: "Legend",
  },

  // Line Plot Settings
  scale_y_log2: {
    name: "Scale Y-Axis (Log2)",
    component: BooleanField,
    group: "Line Plot",
  },
  show_date_range: {
    name: "Show Date Range",
    component: BooleanField,
    group: "Line Plot",
  },
  place_horizontal_line_at: {
    name: "Horizontal Line Position",
    component: NumberField,
    group: "Line Plot",
  },
  horizontal_line_type: {
    name: "Horizontal Line Type",
    component: StringField,
    group: "Line Plot",
  },
  horizontal_line_color: {
    name: "Horizontal Line Color",
    component: StringField,
    group: "Line Plot",
  },
  horizontal_line_size: {
    name: "Horizontal Line Size",
    component: NumberField,
    group: "Line Plot",
  },

  // Waterfall Plot Settings
  sorted_value: {
    name: "Sort by Value",
    component: BooleanField,
    group: "Waterfall Plot",
  },
  threshold: {
    name: "Threshold",
    component: NumberField,
    group: "Waterfall Plot",
  },
  max_values: {
    name: "Maximum Values",
    component: NumberField,
    group: "Waterfall Plot",
  },
  calc_total: {
    name: "Calculate Total",
    component: BooleanField,
    group: "Waterfall Plot",
  },
  total_title: {
    name: "Total Title",
    component: StringField,
    group: "Waterfall Plot",
  },

  // Correlation Plot Settings
  dpi: { name: "DPI", component: NumberField, group: "Correlation Plot" },
  show_annotation: {
    name: "Show Annotations",
    component: BooleanField,
    group: "Correlation Plot",
  },
  annotation_fmt: {
    name: "Annotation Format",
    component: StringField,
    group: "Correlation Plot",
  },
  annotation_fontsize: {
    name: "Annotation Font Size",
    component: NumberField,
    group: "Correlation Plot",
  },
  annotation_color: {
    name: "Annotation Color",
    component: SelectField,
    group: "Correlation Plot",
    args: { options: ANNOTATION_COLOR_OPTIONS },
  },
  annotation_decimals: {
    name: "Annotation Decimals",
    component: NumberField,
    group: "Correlation Plot",
  },
  square: {
    name: "Square Plot",
    component: BooleanField,
    group: "Correlation Plot",
  },
  linewidths: {
    name: "Line Widths",
    component: NumberField,
    group: "Correlation Plot",
  },
  mask_upper: {
    name: "Mask Upper Triangle",
    component: BooleanField,
    group: "Correlation Plot",
  },
};
