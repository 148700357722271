import { PlotType, Periodicity } from "./plot_request_interfaces";

export const PLOT_TYPE_INFO: Record<PlotType, { name: string }> = {
  line_plot: { name: "Line Plot" },
  bar_plot: { name: "Bar Plot" },
  correl_plot: { name: "Correlation Plot" },
};

export const PERIODICITY_INFO: Record<Periodicity, { name: string }> = {
  Days: { name: "Days" },
  Weeks: { name: "Weeks" },
  Months: { name: "Months" },
  Quarters: { name: "Quarters" },
  Years: { name: "Years" },
};
