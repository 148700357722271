import React from "react";
import { PlotSettings } from "../../../../types/plot_settings/plot_settings_interfaces";
import { PLOT_SETTINGS_INFO } from "../../../../types/plot_settings/plot_settings_info";
import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "../../../../../../resources/Accordion";

interface SettingsPanelProps {
  settings: PlotSettings;
  onChange: <K extends keyof PlotSettings>(
    key: K,
    value: PlotSettings[K],
  ) => void;
}

const SettingsPanel: React.FC<SettingsPanelProps> = ({
  settings,
  onChange,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const renderSettingField = (key: keyof PlotSettings | string) => {
    const settingInfo = PLOT_SETTINGS_INFO[key];

    if ("type" in settingInfo && settingInfo.type === "composite") {
      const CompositeComponent = settingInfo.component;
      return (
        <CompositeComponent
          key={key}
          settings={settings}
          onChange={onChange}
          label={settingInfo.name}
          args={settingInfo.args}
          fields={settingInfo.fields}
        />
      );
    }

    const FieldComponent = settingInfo.component;
    if (FieldComponent) {
      return (
        <FieldComponent
          key={key}
          label={settingInfo.name}
          value={settings[key as keyof PlotSettings]}
          onChange={(value: any) => onChange(key as keyof PlotSettings, value)}
          args={settingInfo.args}
        />
      );
    }
  };

  const settingsGroups = React.useMemo(() => {
    const groups: Record<string, (keyof PlotSettings | string)[]> = {};

    Object.keys(PLOT_SETTINGS_INFO).forEach((key) => {
      const settingInfo = PLOT_SETTINGS_INFO[key];
      const group = "group" in settingInfo ? settingInfo.group : "General";
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(key);
    });

    return Object.entries(groups);
  }, []);

  return (
    <div>
      {settingsGroups.map(([groupName, keys]) => (
        <Accordion
          key={groupName}
          expanded={expanded === groupName}
          onChange={handleChange(groupName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${groupName}-content`}
            id={`${groupName}-header`}
          >
            <Typography fontSize={11}>{groupName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" gap="15px">
              {keys.map((key) => renderSettingField(key))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SettingsPanel;
