import { PlotStyle } from "./plot_style_interfaces";
import {
  Transform,
  TransformMethod,
} from "../data_config/data_config_interfaces";
import { PlotType } from "../plot_requests/plot_request_interfaces";
import { LinePlotSettings } from "../plot_settings/plot_settings_interfaces";

interface StyleConfig {
  name: string;
  styleName?: PlotStyle;
  settings?: Partial<LinePlotSettings>;
  defaultTransform: Transform;
  inputs: {
    [key: string]: {
      label: string;
      type: "number" | "string" | "portfolio";
    };
  };
}

export const PLOT_STYLE_INFO: {
  [K in PlotType]: Partial<Record<TransformMethod, StyleConfig>>;
} = {
  line_plot: {
    investment_performance: {
      name: "Investment Performance",
      styleName: "investment_performance",
      defaultTransform: {
        transform: "investment_performance",
        kwargs: { start_val: 100 },
      },
      settings: { scale_y_log2: true },
      inputs: {
        start_val: { label: "Starting Value", type: "number" },
      },
    },
    rolling_volatility: {
      name: "Rolling Volatility",
      defaultTransform: {
        transform: "rolling_volatility",
        kwargs: { window: 20 },
      },
      inputs: {
        window: { label: "Window", type: "number" },
      },
    },
    rolling_returns: {
      name: "Rolling Returns",
      defaultTransform: {
        transform: "rolling_returns",
        kwargs: { window: 252 },
      },
      inputs: {
        window: { label: "Window", type: "number" },
      },
    },
    rolling_drawdowns: {
      name: "Max Drawdown",
      defaultTransform: { transform: "rolling_drawdowns", kwargs: {} },
      inputs: {},
    },
    rolling_correlation: {
      name: "Rolling Correlation",
      defaultTransform: {
        transform: "rolling_correlation",
        kwargs: {
          window: 252,
          benchmark: {
            files: [{ path: "/resolve_data/plotting/files/dropbox/IVV.csv" }],
            name: "Correlation Asset",
            weights: [1],
          },
        },
      },
      inputs: {
        window: { label: "Window", type: "number" },
        benchmark: { label: "Correlation Asset", type: "portfolio" },
      },
    },
  },
  bar_plot: {},
  correl_plot: {},
};
