import React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/ButtonBase";
import { useHistory } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const AppIcon = ({ AppName, path, Icon, disabled }) => {
  const history = useHistory();
  return (
    <Button
      sx={{
        textAlign: "left",
        margin: "25px 80px",
        width: "500px",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          transform: disabled ? "none" : "scale(1.03)",
        },
      }}
      onClick={() => !disabled && history.push(`/${path}`)}
      disabled={disabled}
    >
      <Paper
        elevation={2}
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          gap: "20px",
          padding: "20px 30px",
          width: "300px",
          overflow: "hidden",
          background: (theme) => disabled ? theme.palette.action.disabledBackground : theme.palette.background.paper,
          transition: "all 0.3s ease-in-out",
          "&::before": {
            content: '""',
            position: "absolute",
            inset: 0,
            background: (theme) => `linear-gradient(45deg, 
              ${theme.palette.secondary.light}8c 0%, 
              ${theme.palette.primary.light}40 100%)`,
            opacity: 0,
            transition: "opacity 0.3s ease-in-out",
          },
          "&:hover": {
            boxShadow: disabled ? "none" : "0 6px 12px 0 rgba(0,0,0,0.1)",
            "&::before": {
              opacity: disabled ? 0 : 1,
            },
          },
          "& > *": {
            zIndex: 1,
          },
        }}
      >
        <Icon
          sx={{
            fontSize: "2rem",
            color: (theme) => disabled ? theme.palette.action.disabled : theme.palette.text.secondary,
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: (theme) => disabled ? theme.palette.action.disabled : theme.palette.text.primary,
          }}
        >
          {AppName}
        </Typography>
        {disabled && (
          <Box sx={{ marginLeft: 'auto' }}>
            <LockOutlinedIcon
              sx={{
                color: (theme) => theme.palette.action.disabled,
              }}
            />
          </Box>
        )}
      </Paper>
    </Button>
  );
};

export default AppIcon;