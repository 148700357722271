import React from 'react';
import { Checkbox, Typography, Box, Divider } from '@mui/material/';

interface ExcessReturnsProps {
    onChange: (excess_returns: string[]) => void;
    options: string[];
    useExcessReturns: string[];
}

/**
 * ExcessReturns component allows users to enable or disable excess return transformations in performance table calculations. This is stateless component.
 * 
 * @component
 * @example
 * const options = ['ACWI', 'AGG'];
 * const selectedMetrics = ['ACWI'];
 * 
 * return (
 *   <ExcessReturns
 *     onChange={(selected) => { console.log(selected); }}
 *     options={options}
 *     useExcessReturns={useExcessReturns}
 *   />
 * )
 * 
 * @param {ExcessReturnsProps} props - The props for the component.
 * @param {string[]} props.options - The list of all available options.
 * @param {string[]} props.useExcessReturns - The list of currently selected options.
 * @param {(useExcessReturns: string[]) => void} props.onChange - The callback function when the selection changes.
 */
const ExcessReturns: React.FC<ExcessReturnsProps> = (props: ExcessReturnsProps) => {

    /**
     * Handles checkbox state changes by passing a list of all checked options to the onChange prop.
     * If a checkbox is checked, the corresponding metric is added to the list of selected metrics.
     * If a checkbox is unchecked, the corresponding metric is removed from the list.
     * 
     * @param {string} option - The metric option associated with the checkbox.
     * @param {boolean} checked - The current checked state of the checkbox.
     */
    const handleCheck = (option: string, checked: boolean) => {
        let newSelected = [...props.useExcessReturns];
        if (checked) {
            if (!newSelected.includes(option)) {
                newSelected.push(option);
            }
        } else {
            newSelected = newSelected.filter(item => item !== option);
        }
        props.onChange(newSelected);
    };


    return (
        <>
        {props.options.map((option) => (
            <>
                <Box sx={{px:2}} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography style={{ fontSize: '0.8rem', padding: 1 }}>
                        {option}
                    </Typography>
                    <Checkbox
                        checked={props.useExcessReturns.includes(option)}
                        onChange={(event, checked) => handleCheck(option, checked)}
                    />
                </Box>
                <Divider/>
            </>
        ))}
        </>
    );
};

export default ExcessReturns;