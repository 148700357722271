import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#7accee',
            light: '#e6f7ff',
        },
        secondary: {
            main: '#1c2432',
            light: '#3f3548',
        },
        background: {
            default: '#090910',
            paper: '#2a252d',
        },
        surfaces: {
            lightish: '#C3C3C3',
            darkish: '#505050',
            dark: '#353535',
            darky: '#303030',
            darker: '#101010',
            darkest: '#0000ff',
        },
        text: {
            paperBlend: '#666666',
            darkSurfaceWhite: '#c4c4c4',
            gentleWhite: '#f6f6f6',
        },
        scrollbar: {
            thumb: '#505050',
            track: '#1d1d1d',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.25rem',
            fontWeight: 500,
            lineHeight: 1.2,
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 500,
            lineHeight: 1.3,
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 500,
            lineHeight: 1.4,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 500,
            lineHeight: 1.5,
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: 1.6,
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: 1.6,
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.75,
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.57,
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.43,
        },
        button: {
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.75,
            textTransform: 'uppercase',
        },
        caption: {
            fontSize: '0.75rem',
            fontWeight: 400,
            lineHeight: 1.66,
        },
        overline: {
            fontSize: '0.75rem',
            fontWeight: 400,
            lineHeight: 2.66,
            textTransform: 'uppercase',
        },
    },
});



const globalStyles = {
    '*::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
    },
    '*::-webkit-scrollbar-track': {
        background: darkTheme.palette.scrollbar.track,
    },
    '*::-webkit-scrollbar-thumb': {
        background: darkTheme.palette.scrollbar.thumb,
        borderRadius: '5px',
        '&:hover': {
            background: '#777',
        },
    },
};

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#5a92bf',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#aaaaaa',
            paper: '#f3f3f3',
        },
        text: {
            primary: '#222222',
            secondary: '#757575',
            disabled: '#9e9e9e', 
            paperBlend: '#333333',
            darkSurfaceWhite: '#2c2c2c',
            gentleWhite: '#2a2a2a',
        },
        scrollbar: {
            thumb: '#bcbcbc',
            track: '#e0e0e0',
        },
    },
});

export const theme = {
    ...darkTheme,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: globalStyles,
            },
        },
    },
};