import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Tooltip,
  Grid,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Switch,
  Slide,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import ParticlesBackground from "../common/components/ParticlesBackground";
import SettingsIcon from "@mui/icons-material/Settings";
import { Check } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TestFilesPanel from "../tests/TestFilesPanel";
import TestValidatedUploadField from "../tests/TestValidatedUploadField";
import TestPingSlack from "../tests/TestPingSlack";
import { RAMFormControl, RAMTextField } from "../resources/CustomInputs";
import { HexColorPicker } from "react-colorful";
import RamLogo from "../common/components/RAMLogo";
import TestReportBug from "../tests/TestReportBug";
import TestUserReportContext from "../tests/TestUserReportContext";
import TestJustifyField from "../tests/TestJustifyField";

// Component mapping
const componentMap = {
  "File Panel": TestFilesPanel,
  "Validated Upload Field": TestValidatedUploadField,
  "Ping Slack": TestPingSlack,
  "Report Bug": TestReportBug,
  "User Report Context": TestUserReportContext,
  "Justify Field": TestJustifyField,
};

const TestComponentScreen = () => {
  const theme = useTheme();
  const [isAutoSize, setIsAutoSize] = useState(true);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [stageHeight, setStageHeight] = useState(500);
  const [stageWidth, setStageWidth] = useState(400);
  const [overflow, setOverflow] = useState("auto");
  const [backgroundColor, setBackgroundColor] = useState(
    theme.palette.background.paper,
  );
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const handleColorPickerOpen = () => setColorPickerOpen(true);
  const handleColorPickerClose = () => setColorPickerOpen(false);
  const toggleSettings = () => setSettingsOpen(!settingsOpen);

  const handleComponentChange = (event) => {
    setSelectedComponent(event.target.value);
  };

  const themeColors = [
    { name: "Background Default", value: theme.palette.background.default },
    { name: "Paper", value: theme.palette.background.paper },
    { name: "Primary Main", value: theme.palette.primary.main },
    { name: "Secondary Main", value: theme.palette.secondary.main },
    { name: "Error Main", value: theme.palette.error.main },
    { name: "Warning Main", value: theme.palette.warning.main },
    { name: "Info Main", value: theme.palette.info.main },
    { name: "Success Main", value: theme.palette.success.main },
  ];

  const SelectedComponent = componentMap[selectedComponent] || (() => null);

  return (
    <Box>
      <ParticlesBackground>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          height="100vh"
          pt={3}
        >
          <Box
            sx={{
              position: "fixed",
              top: 16,
              left: 16,
              display: "flex",
              alignItems: "flex-start",
              zIndex: 1000,
            }}
          >
            <RamLogo width="100px" />
          </Box>
          {/* Component selection dropdown */}
          <FormControl sx={{ minWidth: 200, mb: 2 }}>
            <InputLabel id="component-select-label">
              Select Component
            </InputLabel>
            <Select
              labelId="component-select-label"
              value={selectedComponent}
              onChange={handleComponentChange}
              label="Select Component"
            >
              {Object.keys(componentMap).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Stage Settings */}
          <Box
            sx={{
              position: "fixed",
              top: 16,
              right: 16,
              display: "flex",
              alignItems: "flex-start",
              zIndex: 1000,
            }}
          >
            <Slide
              direction="left"
              in={settingsOpen}
              mountOnEnter
              unmountOnExit
            >
              {/* Settings Box */}
              <Paper
                sx={{
                  p: 2,
                  width: "320px",
                  mr: 1,
                }}
              >
                {/* Stage Settings Title */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Stage Settings
                    </Typography>
                  </Grid>

                  {/* Stage Dimensions */}
                  <Grid item xs={6}>
                    <RAMTextField
                      type="number"
                      label="Height"
                      defaultValue={stageHeight}
                      onBlur={(e) => setStageHeight(Number(e.target.value))}
                      fullWidth
                      disabled={isAutoSize}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RAMTextField
                      type="number"
                      label="Width"
                      defaultValue={stageWidth}
                      onBlur={(e) => setStageWidth(Number(e.target.value))}
                      fullWidth
                      disabled={isAutoSize}
                    />
                  </Grid>

                  {/* Overflow Setting */}
                  <Grid item xs={6}>
                    <RAMFormControl fullWidth>
                      <InputLabel id="overflow-select-label">
                        Overflow
                      </InputLabel>
                      <Select
                        labelId="overflow-select-label"
                        id="overflow-select"
                        value={overflow}
                        label="Overflow"
                        onChange={(e) => setOverflow(e.target.value)}
                      >
                        <MenuItem value="visible">Visible</MenuItem>
                        <MenuItem value="hidden">Hidden</MenuItem>
                        <MenuItem value="scroll">Scroll</MenuItem>
                        <MenuItem value="auto">Auto</MenuItem>
                      </Select>
                    </RAMFormControl>
                  </Grid>

                  {/* Background Color Picker */}
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      onClick={handleColorPickerOpen}
                      startIcon={
                        <Box
                          width={24}
                          height={24}
                          bgcolor={backgroundColor}
                          border={1}
                          borderColor="grey.400"
                        />
                      }
                      fullWidth
                    >
                      Color
                    </Button>
                  </Grid>
                </Grid>
                {/* Auto Size Toggle */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isAutoSize}
                        onChange={(e) => setIsAutoSize(e.target.checked)}
                      />
                    }
                    label="Auto Size"
                  />
                </Grid>
              </Paper>
            </Slide>

            {/* Button to open stage settings */}
            <IconButton
              onClick={toggleSettings}
              sx={{
                color: "white",
                backgroundColor: settingsOpen
                  ? "rgba(255, 255, 255, 0.2)"
                  : "transparent",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                },
                transition: "background-color 0.3s",
              }}
            >
              <SettingsIcon />
            </IconButton>
          </Box>

          {/* Color Picker Dialog */}
          <Dialog
            open={colorPickerOpen}
            onClose={handleColorPickerClose}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle>Choose Background Color</DialogTitle>
            <DialogContent>
              <Box width="100%" margin="15px 0">
                <HexColorPicker
                  color={backgroundColor}
                  onChange={setBackgroundColor}
                  style={{ width: "100%", height: "200px" }}
                />
              </Box>
              <Typography variant="subtitle1" gutterBottom>
                Theme Colors:
              </Typography>
              <Grid container spacing={2} justifyContent="flex-start">
                {themeColors.map((color) => (
                  <Grid item key={color.name}>
                    <Tooltip title={color.name} arrow>
                      <IconButton
                        onClick={() => setBackgroundColor(color.value)}
                        sx={{
                          width: 40,
                          height: 40,
                          padding: 0,
                          backgroundColor: color.value,
                          "&:hover": {
                            backgroundColor: alpha(color.value, 0.8),
                          },
                        }}
                      >
                        {backgroundColor === color.value && <Check />}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleColorPickerClose}>Close</Button>
            </DialogActions>
          </Dialog>

          {/* Wrapper to center stage */}
          <Box
            height="75%"
            display="flex"
            width="75%"
            alignItems="center"
            justifyContent="center"
          >
            {/* Stage where selected component is rendered */}
            <Box
              width={isAutoSize ? "auto" : stageWidth}
              height={isAutoSize ? "auto" : stageHeight}
              overflow={overflow}
              backgroundColor={backgroundColor}
            >
              <SelectedComponent />
            </Box>
          </Box>
        </Box>
      </ParticlesBackground>
    </Box>
  );
};

export default TestComponentScreen;
