interface EnvVars {
  REACT_APP_BACKEND_ENDPOINT: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUTH0_AUDIENCE: string;
  REACT_APP_PODCAST_POST_PORT: number;
  REACT_APP_DOCUMENT_MANAGER_PORT: number;
  REACT_APP_PLOTTING_APP_PORT: number;
}

function getEnvVar(key: keyof EnvVars): string {
  const value = process.env[key];
  if (!value) {
    throw new Error(`Missing required environment variable: ${key}`);
  }
  return value;
}

export const env: EnvVars = {
  REACT_APP_BACKEND_ENDPOINT: getEnvVar("REACT_APP_BACKEND_ENDPOINT"),
  REACT_APP_AUTH0_DOMAIN: getEnvVar("REACT_APP_AUTH0_DOMAIN"),
  REACT_APP_AUTH0_CLIENT_ID: getEnvVar("REACT_APP_AUTH0_CLIENT_ID"),
  REACT_APP_AUTH0_AUDIENCE: getEnvVar("REACT_APP_AUTH0_AUDIENCE"),
  REACT_APP_PODCAST_POST_PORT: Number(getEnvVar("REACT_APP_PODCAST_POST_PORT")),
  REACT_APP_DOCUMENT_MANAGER_PORT: Number(
    getEnvVar("REACT_APP_DOCUMENT_MANAGER_PORT"),
  ),
  REACT_APP_PLOTTING_APP_PORT: Number(getEnvVar("REACT_APP_PLOTTING_APP_PORT")),
};
