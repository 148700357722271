import React from "react";
import { Stack, Button } from "@mui/material";
import { RAMTextField } from "../../../../../resources/CustomInputs";
import { useDialogContext } from "../DialogContext";

interface QuickEnterDateProps {
  onChange: (dateRange: { start_date: string; end_date: string }) => void;
}

const QuickEnterDate: React.FC<QuickEnterDateProps> = ({ onChange }) => {
  const [date, setDate] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const [helperText, setHelperText] = React.useState<string>("");
  const { closeDialog } = useDialogContext();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const dates = date.split("::").map((d) => d.trim());
    let startDate = dates[0];
    let endDate = dates[1] || "";

    const getLastDayOfPrevious = (year: string, month?: string) => {
      if (month) {
        const date = new Date(parseInt(year), parseInt(month), 0);
        return date.toISOString().split("T")[0];
      } else {
        return `${year}-12-31`;
      }
    };

    const getYesterday = () => {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      return date.toISOString().split("T")[0];
    };

    const shorthandRegex = /^(YTD|\d+Y|\d+M|\d+D)$/;

    if (!shorthandRegex.test(startDate)) {
      const startParts = startDate.split("-");
      if (startParts.length === 1) {
        startDate = getLastDayOfPrevious(startParts[0]);
      } else if (startParts.length === 2) {
        startDate = getLastDayOfPrevious(startParts[0], startParts[1]);
      }
    }

    if (endDate && !shorthandRegex.test(endDate)) {
      const endParts = endDate.split("-");
      if (endParts.length === 1) {
        endDate = getLastDayOfPrevious(endParts[0]);
      } else if (endParts.length === 2) {
        endDate = getLastDayOfPrevious(endParts[0], endParts[1]);
      }
    } else if (!endDate) {
      endDate = getYesterday();
    }

    onChange({
      start_date: startDate,
      end_date: endDate,
    });
    closeDialog();
  };

  const validateDate = (input: string): boolean => {
    const regex =
      /^(?:(?:YTD)|(?:\d{4}(?:-\d{2}(?:-\d{2})?)?)|(?:\d+Y|\d+M|\d+D))(?:::(?:(?:\d{4}(?:-\d{2}(?:-\d{2})?)?)|(?:\d+Y|\d+M|\d+D)))?$/;
    return regex.test(input);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setDate(inputValue);
    if (validateDate(inputValue)) {
      setError(false);
      setHelperText("");
    } else {
      setError(true);
      setHelperText("Enter date in yyyy-mm-dd::yyyy-mm-dd format");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={1}>
        <RAMTextField
          required
          error={error}
          helperText={helperText}
          label="Date"
          placeholder="Enter date in yyyy-mm-dd::yyyy-mm-dd format"
          value={date}
          onChange={handleDateChange}
        />
        <Button
          type="submit"
          fullWidth
          sx={{ height: "30px", py: 0 }}
          variant="outlined"
        >
          Done
        </Button>
      </Stack>
    </form>
  );
};

export default QuickEnterDate;
