import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from "./LoadingSpinner";

const ProtectedRoute = ({ component: Component, requiredScopes, ...args }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [accessGranted, setAccessGranted] = useState(null);

  useEffect(() => {
    const checkAccess = async () => {
      if (isLoading || !isAuthenticated) return;

      try {
        const token = await getAccessTokenSilently();
        const tokenPayload = JSON.parse(atob(token.split('.')[1]));
        const userPermissions = tokenPayload.permissions || [];

        const hasRequiredScopes = !requiredScopes || requiredScopes.length === 0 || 
          requiredScopes.some(scope => userPermissions.includes(scope));

        setAccessGranted(hasRequiredScopes);
      } catch (error) {
        console.error("Error checking permissions:", error);
        setAccessGranted(false);
      }
    };

    checkAccess();
  }, [isLoading, isAuthenticated, getAccessTokenSilently, requiredScopes]);

  const AuthenticatedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <LoadingSpinner />,
  });

  if (isLoading || accessGranted === null) {
    return <LoadingSpinner />;
  }

  return (
    <Route
      {...args}
      render={(props) =>
        accessGranted ? (
          <AuthenticatedComponent {...props} />
        ) : (
          <div>You don't have the required permissions to access this page.</div>
        )
      }
    />
  );
};

export default ProtectedRoute;