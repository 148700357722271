import React, { createContext, useContext, ReactNode, useState } from "react";
import {
  STARTUP_PLOT_TYPE,
  DEFAULT_PLOT_REQUESTS,
} from "../types/plot_requests/default_plot_requests";
import {
  defaultPerformanceTableRequest,
  PerformanceTableRequest,
} from "../types/plot_requests/performance_table";
import {
  PlotType,
  PlotRequest,
} from "../types/plot_requests/plot_request_interfaces";

interface PlotContextContextType {
  plot: {
    config: PlotRequest;
    update: <T extends PlotRequest>(updates: Partial<T>) => void;
    setType: (plotType: PlotType) => void;
  };
  performanceTable: {
    config: PerformanceTableRequest;
    update: (updates: Partial<PerformanceTableRequest>) => void;
  };
}

const PlotConfigContext = createContext<PlotContextContextType | undefined>(
  undefined,
);

interface PlotContextProviderProps {
  children: ReactNode;
}

export const PlotContextProvider: React.FC<PlotContextProviderProps> = ({
  children,
}) => {
  const [plotConfig, setPlotConfig] = useState<PlotRequest>(STARTUP_PLOT_TYPE);
  const [performanceTableConfig, setPerformanceTableConfig] =
    useState<PerformanceTableRequest>(defaultPerformanceTableRequest);

  /** Updates plot config with partial changes, preserving existing values */
  const updatePlotConfig = <T extends PlotRequest>(updates: Partial<T>) => {
    setPlotConfig((prevConfig) => {
      const newConfig = { ...prevConfig, ...updates } as T;
      console.log("Updated plot config:", newConfig);
      return newConfig;
    });
  };
  /** Updates performance table config with partial changes, preserving existing values */
  const updatePerformanceTableConfig = (
    updates: Partial<PerformanceTableRequest>,
  ) => {
    setPerformanceTableConfig((prevConfig) => {
      return { ...prevConfig, ...updates };
    });
  };

  /** Updates plot config to default settings for given plot type */
  const setPlotType = (plotType: PlotType) => {
    setPlotConfig((prevConfig) => {
      const newConfig = {
        ...DEFAULT_PLOT_REQUESTS[plotType],
        plot_theme: prevConfig.theme,
        data_config: {
          ...prevConfig.data_config,
          transform: DEFAULT_PLOT_REQUESTS[plotType].data_config.transform,
        },
      };
      return newConfig;
    });
  };

  return (
    <PlotConfigContext.Provider
      value={{
        plot: {
          config: plotConfig,
          update: updatePlotConfig,
          setType: setPlotType,
        },
        performanceTable: {
          config: performanceTableConfig,
          update: updatePerformanceTableConfig,
        },
      }}
    >
      {children}
    </PlotConfigContext.Provider>
  );
};

export const usePlotContext = () => {
  const context = useContext(PlotConfigContext);
  if (context === undefined) {
    throw new Error("usePlotContext must be used within a PlotConfigProvider");
  }
  return context;
};
