import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { pingSlack } from "../common/utils/pingSlack";
import { Button, Paper, Stack, TextField } from "@mui/material";

const TestPingSlack: React.FC = () => {
  const [slackMessage, setSlackMessage] = useState<string>("");
  const { getAccessTokenSilently } = useAuth0();

  const handlePingSlack = async (message: string) => {
    const token = await getAccessTokenSilently();
    const response = await pingSlack(message, token);
    if (response.isSuccess) {
      alert("message sent to slack");
    } else {
      alert("failed to send");
    }
    setSlackMessage("");
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Stack gap={1}>
        <TextField
          label="Message"
          value={slackMessage}
          onChange={(e) => setSlackMessage(e.target.value)}
        />
        <Button
          onClick={() => handlePingSlack(slackMessage!)}
          disabled={slackMessage === ""}
        >
          PING SLACK
        </Button>
      </Stack>
    </Paper>
  );
};

export default TestPingSlack;
