import React from "react";
import "./App.css";
import AppsHomeScreen from "./screens/AppsHomeScreen";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { theme } from "./theme.js";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import LoginPage from "./apps/podcast_post/screens/LoginPage";
import { appConfigs } from "./app_configs";
import ProtectedRoute from "./common/utils/ProtectedRoute";
import TestComponentScreen from "./screens/TestComponentScreen";
import { UserReportProvider } from "./common/contexts/UserReportContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <UserReportProvider>
          <Router>
            <Switch>
              <Route exact path="/" component={AppsHomeScreen} />
              {appConfigs.map((app) => (
                <ProtectedRoute
                  key={app.path}
                  exact
                  path={`/${app.path}`}
                  component={app.component}
                  requiredScopes={app.requiredScopes}
                />
              ))}
              <Route exact path="/login" component={LoginPage} />
              {process.env.REACT_APP_DEV_MODE === "true" && (
                <Route
                  exact
                  path="/components"
                  component={TestComponentScreen}
                />
              )}
            </Switch>
          </Router>
        </UserReportProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
