import React from "react";
import { Box } from "@mui/material";
import PlotUI from "./PlotUI";
import { PlotContextProvider } from "../contexts/PlotContext";
import { DataContextProvider } from "../contexts/DataContext";

const PlottingScreen: React.FC = () => {
  return (
    <Box height="100vh">
      <DataContextProvider>
        <PlotContextProvider>
          <PlotUI />
        </PlotContextProvider>
      </DataContextProvider>
    </Box>
  );
};

export default PlottingScreen;
