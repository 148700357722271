import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDataContext } from "../../contexts/DataContext";

interface AssetOption {
  path: string;
  name: string;
  bin: string;
}

interface AssetInputProps {
  multiple?: boolean;
  onChange: (value: string | string[]) => void;
  value: string | string[];
  label?: string;
  fullWidth?: boolean;
}

const AssetInput: React.FC<AssetInputProps> = ({
  multiple = false,
  onChange,
  value,
  label = "Select Asset",
  fullWidth = true,
}) => {
  const { filesMetadata } = useDataContext();

  // Create and sort options from filesMetadata
  const options: AssetOption[] = React.useMemo(() => {
    return Object.entries(filesMetadata)
      .filter(([, metadata]) => metadata.isValid)
      .map(([path, metadata]) => ({
        path,
        name: metadata.name,
        bin: metadata.bin,
      }))
      .sort((a, b) => a.bin.localeCompare(b.bin)); // Sort alphabetically by bin
  }, [filesMetadata]);

  // Handle changes in selection
  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: AssetOption | AssetOption[] | null,
  ) => {
    if (multiple) {
      onChange((newValue as AssetOption[])?.map((option) => option.path) || []);
    } else {
      onChange((newValue as AssetOption)?.path || "");
    }
  };

  // Determine current value based on multiple selection or single selection
  const currentValue = React.useMemo(() => {
    if (multiple) {
      return options.filter((option) =>
        (value as string[]).includes(option.path),
      );
    }
    return options.find((option) => option.path === value) || null;
  }, [options, value, multiple]);

  return (
    <Autocomplete
      multiple={multiple}
      fullWidth={fullWidth}
      sx={{
        "& .MuiAutocomplete-inputRoot": {
          padding: "1px 4px !important",
        },
        "& .MuiInputLabel-root": {
          transform: "translate(14px, 9px) scale(1)",
        },
        "& .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      }}
      options={options}
      groupBy={(option) => option.bin}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={handleChange}
      value={currentValue}
      isOptionEqualToValue={(option, value) => option.path === value.path}
      renderOption={(props, option) => (
        <li {...props} key={option.path}>
          {option.name}
        </li>
      )}
    />
  );
};

export default AssetInput;
