import React from "react";
import { Stack, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import {
  downloadPlotFile,
  ExportType,
  EXPORT_TYPE_INFO,
} from "../../../utils/requestUtils";
import { PlotRequest } from "../../../types/plot_requests/plot_request_interfaces";
import { useDialogContext } from "../DialogContext";
import { useUserReport } from "../../../../../common/contexts/UserReportContext";

interface QuickExportProps {
  plotRequest: PlotRequest;
}

const QuickExport: React.FC<QuickExportProps> = ({ plotRequest }) => {
  const { openErrorPrompt } = useUserReport();
  const { getAccessTokenSilently } = useAuth0();
  const { closeDialog } = useDialogContext();

  const downloadPlot = async (fileType: ExportType) => {
    const token = await getAccessTokenSilently();
    const response = await downloadPlotFile(plotRequest, fileType, token);
    if (response.isSuccess) {
      const plotFile = response.body;
      const downloadUrl = window.URL.createObjectURL(plotFile);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `plot.${fileType}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      closeDialog();
    } else {
      const warning = "An error occured while downloading the plot.";
      const slackMessage = `The Plotting App Failed to download a plot`;
      openErrorPrompt(warning, slackMessage);
    }
  };

  return (
    <Stack gap={1}>
      {(Object.keys(EXPORT_TYPE_INFO) as ExportType[]).map((fileType) => (
        <Button
          key={fileType}
          onClick={() => downloadPlot(fileType)}
          fullWidth
          variant="outlined"
        >
          Export as {EXPORT_TYPE_INFO[fileType].name}
        </Button>
      ))}
    </Stack>
  );
};

export default QuickExport;
