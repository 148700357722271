import React, { createContext, useState, useContext, useEffect } from "react";
import { Modal, Box, IconButton, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import FeedbackForm from "../components/FeedbackForm";
import { useAuth0 } from "@auth0/auth0-react";
import { pingSlack } from "../utils/pingSlack";

interface ReportBugProps {
  open: boolean;
  onClose: () => void;
  appName?: string;
}
const ReportBug: React.FC<ReportBugProps> = ({
  open,
  onClose,
  appName = "",
}) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const handlePingSlack = async (message: string) => {
    const token = await getAccessTokenSilently();
    const slackMessage = `
BUG REPORTED - ${appName}

Reported by: ${user?.name || "Unknown"}
Description: ${message}
    `.trim();
    await pingSlack(slackMessage, token);
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <FeedbackForm
          onSubmit={handlePingSlack}
          title="Report a Bug"
          submitButtonText="Submit Bug Report"
          placeholder="Describe the bug you encountered..."
          onClose={handleOnClose}
        />
      </Box>
    </Modal>
  );
};

interface FeatureRequestProps {
  open: boolean;
  onClose: () => void;
  appName?: string;
}
const FeatureRequest: React.FC<FeatureRequestProps> = ({
  open,
  onClose,
  appName = "",
}) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const handlePingSlack = async (message: string) => {
    const token = await getAccessTokenSilently();
    const slackMessage = `
Feature Request - ${appName}

Requested by: ${user?.name || "Unknown"}
Description: ${message}
    `.trim();
    await pingSlack(slackMessage, token);
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <FeedbackForm
          onSubmit={handlePingSlack}
          title="Request a Feature"
          submitButtonText="Submit Feature Request"
          placeholder="Describe the feature you would like to see implemented..."
          onClose={handleOnClose}
        />
      </Box>
    </Modal>
  );
};

interface ErrorMessageProps {
  open: boolean;
  onClose: () => void;
  warning: string;
  slackMessage: string;
}
const ErrorPrompt: React.FC<ErrorMessageProps> = ({
  open,
  onClose,
  warning,
  slackMessage,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    const pingSlackOnMount = async () => {
      if (open) {
        const token = await getAccessTokenSilently();
        const parsedMessage = `
ERROR Occurred

Occurred for: ${user?.name || "Unknown"}
Description: ${slackMessage}
        `.trim();
        await pingSlack(parsedMessage, token);
      }
    };

    pingSlackOnMount();
  }, [open, getAccessTokenSilently, user, slackMessage]);

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: 3,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mb: 2, pb: 1, borderBottom: 1, borderColor: "divider" }}>
          <Typography variant="h6" component="h2" color="error">
            Error Occurred
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {warning}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <CheckCircleIcon
            sx={{ fontSize: 13, color: "success.main", mr: 1 }}
          />
          <Typography variant="caption" color="text.secondary">
            Our team has been notified and is working on a solution.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

interface UserReportContextType {
  openBugReport: (currentApp?: string) => void;
  openFeatureRequest: (currentApp?: string) => void;
  openErrorPrompt: (warning: string, slackMessage: string) => void;
}

const UserReportContext = createContext<UserReportContextType | undefined>(
  undefined,
);

export const UserReportProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isReportBugOpen, setIsReportBugOpen] = useState(false);
  const [isFeatureRequestOpen, setFeatureRequestOpen] = useState(false);
  const [isErrorPromptOpen, setErrorPromptOpen] = useState(false);
  const [errorWarning, setErrorWarning] = useState("");
  const [errorSlackMessage, setErrorSlackMessage] = useState("");
  const [currentApp, setCurrentApp] = useState("");

  const openBugReport = (currentApp?: string) => {
    setCurrentApp(currentApp || "");
    setIsReportBugOpen(true);
  };
  const closeBugReport = () => {
    setIsReportBugOpen(false);
    setCurrentApp("");
  };
  const openFeatureRequest = (currentApp?: string) => {
    setCurrentApp(currentApp || "");
    setFeatureRequestOpen(true);
  };
  const closeFeatureRequest = () => {
    setFeatureRequestOpen(false);
    setCurrentApp("");
  };
  const openErrorPrompt = (warning: string, slackMessage: string) => {
    setErrorWarning(warning);
    setErrorSlackMessage(slackMessage);
    setErrorPromptOpen(true);
  };
  const closeErrorPrompt = () => {
    setErrorPromptOpen(false);
    setErrorWarning("");
    setErrorSlackMessage("");
  };

  return (
    <UserReportContext.Provider
      value={{ openBugReport, openFeatureRequest, openErrorPrompt }}
    >
      {children}
      <ReportBug
        appName={currentApp}
        open={isReportBugOpen}
        onClose={closeBugReport}
      />
      <FeatureRequest
        appName={currentApp}
        open={isFeatureRequestOpen}
        onClose={closeFeatureRequest}
      />
      <ErrorPrompt
        open={isErrorPromptOpen}
        onClose={closeErrorPrompt}
        warning={errorWarning}
        slackMessage={errorSlackMessage}
      />
    </UserReportContext.Provider>
  );
};

export const useUserReport = () => {
  const context = useContext(UserReportContext);
  if (context === undefined) {
    throw new Error("useUserReport must be used within a BugReportProvider");
  }
  return context;
};
