import React, { useState } from "react";
import { Stack, Button } from "@mui/material";
import AssetInput from "../../common/AssetInput";
import { useDialogContext } from "../DialogContext";
import { Portfolio } from "../../../types/data_config/data_config_interfaces";
import { useDataContext } from "../../../contexts/DataContext";

interface QuickAddElementProps {
  onChange: (newPortfolios: Portfolio[]) => void;
}

const QuickAddElement: React.FC<QuickAddElementProps> = ({ onChange }) => {
  const [selectedPaths, setSelectedPaths] = useState<string[]>([]);
  const { closeDialog } = useDialogContext();
  const { filesMetadata } = useDataContext();

  const handleSubmit = () => {
    const newPortfolios: Portfolio[] = selectedPaths.map((path) => {
      const metadata = filesMetadata[path];
      return {
        files: [{ path: path }],
        name: metadata.name,
        weights: [1],
      };
    });

    onChange(newPortfolios);
    closeDialog();
  };

  const handleAssetChange = (value: string | string[]) => {
    setSelectedPaths(Array.isArray(value) ? value : [value]);
  };

  return (
    <Stack gap={1}>
      <AssetInput
        multiple
        value={selectedPaths}
        onChange={handleAssetChange}
        label="Select Assets"
      />
      <Button
        onClick={handleSubmit}
        fullWidth
        sx={{ height: "30px", py: 0 }}
        variant="outlined"
        disabled={selectedPaths.length === 0}
      >
        Done
      </Button>
    </Stack>
  );
};

export default QuickAddElement;
