import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import particlesOptions from "../../resources/particlesOptions";
import { useTheme } from "@mui/material/styles";
import { loadSlim } from "@tsparticles/slim";

const ParticlesBackground = ({ children, customParticlesConfig }) => {
  const [init, setInit] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const defaultParticlesConfig = particlesOptions({
    backgroundColor: theme.palette.background.default,
    primaryColor: theme.palette.primary.main,
  });

  const particlesConfig = customParticlesConfig || defaultParticlesConfig;

  return (
    <Box sx={{ position: "relative", height: "100vh", overflow: "hidden" }}>
      <Particles
        id="tsparticles"
        options={particlesConfig}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
      <Box sx={{ position: "relative", zIndex: 1 }}>{children}</Box>
    </Box>
  );
};

export default ParticlesBackground;
