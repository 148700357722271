import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  IconButton,
  Stack,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface FeedbackFormProps {
  onSubmit: (message: string) => Promise<void>;
  title?: string;
  description?: string;
  submitButtonText?: string;
  placeholder?: string;
  onClose?: () => void;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  onSubmit,
  title = "Submit Feedback",
  description = null,
  submitButtonText = "Submit",
  placeholder = "Enter your message here...",
  onClose = null,
}) => {
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim()) {
      setIsSubmitting(true);
      try {
        await onSubmit(message);
        setIsSubmitted(true);
        setMessage("");
      } catch (error) {
        console.error("Error submitting feedback:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ margin: "auto" }}>
      <Stack gap={2}>
        {/* Top Row (Title and Close button) */}
        <Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            {onClose && (
              <IconButton onClick={onClose} sx={{ p: 0 }}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Divider />
        </Box>

        {/* Description and Text Input */}
        <Box>
          <Typography variant="body2" gutterBottom>
            {description}
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={placeholder}
            variant="outlined"
          />
        </Box>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          loading={isSubmitting}
          disabled={message.trim() === "" || isSubmitted}
          startIcon={isSubmitted ? <CheckCircleIcon /> : null}
        >
          {isSubmitted ? "" : submitButtonText}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default FeedbackForm;
