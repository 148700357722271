export const DEFAULT_MAIN_DISCLAIMER = `All results presented are hypothetical and do not represent actual performance specific to any trading program. No representation is being made that any account will or is likely to achieve profits or losses similar to the results shown. The risk of investing in securities can be substantial and past performance is not indicative of future results. Please see below for Important Information regarding Hypothetical Performance and General Risks of Investing. These materials do not constitute an offer or solicitation of an offer to make an investment in any funds or separately managed accounts. Property of ReSolve Asset Management. Not for general distribution.`;

export const DEFAULT_DISCLAIMERS = {
  hypothetical: {
    name: "Hypothetical Performance Disclaimer",
    content: `All performance results are hypothetical. Hypothetical performance results have many inherent limitations. No representation is being made that any account or fund will or is likely to achieve profits or losses similar to those shown. There are frequently differences between hypothetical performance results and the actual results achieved by any trading program. One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. Moreover, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results.`,
  },
  offer: {
    name: "Not an Offer or Solicitation",
    content: `The information presented here is for informational purposes only and does not constitute an offer or invitation to subscribe for or purchase any securities, products or services. This material is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to local law or regulation. The securities, products and services described herein may not be available to all persons in all jurisdictions.`,
  },
  risks: {
    name: "General Risks of Investing",
    content: `All investments in securities involve risks, including the risk of losing all of the invested capital. Past performance of a security, market, portfolio or investment strategy does not guarantee future results. There is no assurance that a portfolio or strategy will achieve its investment objective. Diversification does not ensure profit or protect against loss in declining markets.`,
  },
  data: {
    name: "Data Disclaimer",
    content:
      "While we strive to ensure the accuracy and reliability of the data presented, we cannot guarantee that the information is complete, error-free, or up-to-date. Users are advised to independently verify any data before making decisions based on the information provided. ReSolve Asset Management is not liable for any data errors or actions taken in reliance on the information provided.",
  },
};
