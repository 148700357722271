import {
  makeRequest,
  RequestConfig,
  ResponseData,
} from "../../../common/utils/requestUtils";
import { FilesMetadata } from "../types/data_config/data_config_interfaces";
import { PerformanceTableRequest } from "../types/plot_requests/performance_table";
import { PlotRequest } from "../types/plot_requests/plot_request_interfaces";
import {
  makeRequestV2,
  ResponseDataV2,
} from "../../../common/utils/requestsV2";
import { EndpointName } from "../../../config/apiPaths";

const port = process.env.REACT_APP_PLOTTING_APP_PORT;

const requestConfigs: Record<string, RequestConfig> = {
  downloadPlotFile: {
    method: "POST",
    url: `:${port}/plotting/plot/download/`,
    responseType: "blob",
  },
  getDataOptions: {
    method: "GET",
    url: `:${port}/plotting/data/metadata`,
    responseType: "json",
  },
  renameFile: {
    method: "PATCH",
    url: `:${port}/plotting/data/rename`,
    responseType: "json",
  },
};

export async function getPlot(
  PlotRequest: PlotRequest | PerformanceTableRequest,
  token: string,
): Promise<ResponseData<Blob>> {
  const config = requestConfigs.downloadPlotFile;

  const response = await makeRequest<Blob>(
    config.method,
    config.url + "html",
    token,
    PlotRequest,
    config.responseType,
  );
  return response;
}

export type ExportType = "pdf" | "png" | "xlsx";
export const EXPORT_TYPE_INFO = {
  pdf: { name: "PDF" },
  png: { name: "PNG" },
  xlsx: { name: "Excel Data" },
};

export async function downloadPlotFile(
  plotRequest: PlotRequest,
  fileType: ExportType,
  token: string,
) {
  const config = requestConfigs.downloadPlotFile;
  const response = await makeRequest<Blob>(
    config.method,
    `${config.url}${fileType}`,
    token,
    plotRequest,
    config.responseType,
  );
  return response;
}

export async function getDataOptions(
  token: string,
): Promise<ResponseData<FilesMetadata>> {
  const config = requestConfigs.getDataOptions;
  const response = await makeRequest<FilesMetadata>(
    config.method,
    config.url,
    token,
    undefined,
    config.responseType,
  );
  return response;
}

export async function renameFile(
  path: string,
  newName: string,
  token: string,
): Promise<ResponseData<FilesMetadata>> {
  const config = requestConfigs.renameFile;
  const response = await makeRequest<FilesMetadata>(
    config.method,
    `${config.url}/${path}/${newName}`,
    token,
    undefined,
    config.responseType,
  );
  return response;
}

export async function updateFile(
  path: string,
  file: File,
  token: string,
): Promise<ResponseDataV2> {
  const formData = new FormData();
  formData.append("file", file);

  const response = await makeRequestV2(
    EndpointName.UPDATE_FILE,
    token,
    formData,
    "PATCH",
    undefined,
    undefined,
    path,
  );
  return response;
}

export async function validateFile(
  file: File,
  token: string,
): Promise<ResponseDataV2> {
  const formData = new FormData();
  formData.append("file", file);

  const response = await makeRequestV2(
    EndpointName.VALIDATE_CSV,
    token,
    formData,
    "PUT",
  );
  return response;
}

export async function listBins(token: string): Promise<ResponseDataV2> {
  const response = await makeRequestV2(
    EndpointName.LIST_BINS,
    token,
    null,
    "GET",
  );
  return response;
}

export async function uploadFile(
  file: File,
  fileName: string,
  binName: string,
  token: string,
): Promise<ResponseDataV2> {
  const formData = new FormData();
  formData.append("file", file);

  const response = await makeRequestV2(
    EndpointName.UPLOAD_FILE,
    token,
    formData,
    "POST",
    undefined,
    undefined,
    binName,
    fileName,
  );
  return response;
}
