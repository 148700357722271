import { styled, TextField, FormControl, Autocomplete } from "@mui/material";

export const RAMTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    padding: "0",
  },
  "& .MuiInputBase-input": {
    padding: "8px 8px",
    fontSize: "13px",
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 8px) scale(1)",
    fontSize: "13px",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
    fontSize: "13px",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1rem",
  },
  "& .MuiChip-root": {
    fontSize: "10px",
    padding: 0,
  },
});

export const RAMFormControl = styled(FormControl)({
  "& .MuiInputBase-root": {
    padding: "0",
    lineHeight: "unset",
  },
  "& .MuiInputBase-input": {
    padding: "8px 8px",
    fontSize: "13px",
  },
  "& .MuiInputLabel-root": {
    transform: "translate(14px, 8px) scale(1)",
    fontSize: "13px",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75)",
    fontSize: "13px",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1rem",
  },
  "& .MuiChip-root": {
    fontSize: "10px",
    padding: 0,
    height: "unset",
    "& .Mui-Select": {
      height: 35,
    },
  },
});
