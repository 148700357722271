import { useCallback, useEffect, useState, useRef } from "react";

const useElementSize = (ref, delay = 0) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const timeoutRef = useRef(null);
  const lastSizeRef = useRef({ width: 0, height: 0 });

  const updateSize = useCallback(() => {
    if (ref.current) {
      const newSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };

      if (delay > 0) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          if (ref.current) {
            const currentSize = {
              width: ref.current.clientWidth,
              height: ref.current.clientHeight,
            };

            if (
              currentSize.width !== newSize.width ||
              currentSize.height !== newSize.height
            ) {
              // Size is still changing, delay again
              updateSize();
            } else {
              // Size has stabilized
              setSize(currentSize);
              lastSizeRef.current = currentSize;
            }
          }
        }, delay);
      } else {
        // No delay, update immediately
        setSize(newSize);
        lastSizeRef.current = newSize;
      }
    }
  }, [ref, delay]);

  useEffect(() => {
    if (!ref.current) return;

    updateSize(); // Initial size update

    const resizeObserver = new ResizeObserver(updateSize);
    resizeObserver.observe(ref.current);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [ref, updateSize]);

  return size;
};

export default useElementSize;
