import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getDataOptions } from "../utils/requestUtils";
import { FilesMetadata } from "../types/data_config/data_config_interfaces";
import { useUserReport } from "../../../common/contexts/UserReportContext";

interface DataContextType {
  filesMetadata: FilesMetadata;
  refreshDataOptions: () => void;
  setFilesMetadata: (filesMetadata: FilesMetadata) => void;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

interface DataContextProviderProps {
  children: ReactNode;
}

export const DataContextProvider: React.FC<DataContextProviderProps> = ({
  children,
}) => {
  const [filesMetadata, setFilesMetadata] = useState<FilesMetadata>({});
  const { getAccessTokenSilently } = useAuth0();
  const { openErrorPrompt } = useUserReport();

  const refreshDataOptions = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    const response = await getDataOptions(token);
    if (response.isSuccess) {
      const filesMetadata: FilesMetadata = { ...response.body };
      setFilesMetadata(filesMetadata);
    } else {
      const warning = "The page failed to load critical resources.";
      const slackMessage =
        "The Plotting App failed to load metadata for files.";
      openErrorPrompt(warning, slackMessage);
    }
  }, [getAccessTokenSilently]);

  // Fetch Data Options on mount
  useEffect(() => {
    refreshDataOptions();
  }, [refreshDataOptions]);

  return (
    <DataContext.Provider
      value={{ filesMetadata, refreshDataOptions, setFilesMetadata }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useDataContext must be used within a DataContextProvider");
  }
  return context;
};
