import FilesPanel from "../apps/plotting/components/panel_drawer/panels/FilesPanel/FilesPanel";
import { DataContextProvider } from "../apps/plotting/contexts/DataContext";
import { Box } from "@mui/material";

const TestFilesPanel = () => {
  return (
    <DataContextProvider>
      <FilesPanel />
    </DataContextProvider>
  );
};

export default TestFilesPanel;
