import React, { useRef } from "react";
import { Box, MenuItem, Select, InputLabel } from "@mui/material/";
import {
  RAMFormControl,
  RAMTextField,
} from "../../../../../../../resources/CustomInputs";
import { BaseTheme } from "../../../../../types/plot_theme/plot_theme_interfaces";
import { PerformanceTableSettings } from "../../../../../types/plot_requests/performance_table";

export interface TableSettingsProps {
  settings: PerformanceTableSettings;
  theme: BaseTheme;
  onChange: (settings: PerformanceTableSettings) => void;
}

const TableSettings: React.FC<TableSettingsProps> = ({
  onChange,
  theme,
  settings,
}) => {
  const fontSizeRef = useRef<HTMLInputElement>(null);

  // onChange Handler - Setting Inputs
  const handleSettingChange = <T extends keyof TableSettingsProps["settings"]>(
    setting: T,
    value: TableSettingsProps["settings"][T],
  ) => {
    const updatedSettings: TableSettingsProps["settings"] = { ...settings };
    updatedSettings[setting] = value;
    onChange(updatedSettings);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        width="100%"
        gap="10px"
        display="flex"
        justifyContent="space-around"
        paddingBottom="15px"
      >
        {/* TABLE HEADER COLOR */}
        <RAMFormControl sx={{ minWidth: "60px" }}>
          <InputLabel>Header</InputLabel>
          <Select
            value={settings.header_color}
            onChange={(event) =>
              handleSettingChange("header_color", event.target.value as string)
            }
            label="Header Color"
          >
            {theme.colors.palette.map((color) => (
              <MenuItem key={color} value={color}>
                <Box
                  width="30px"
                  height="18px"
                  sx={{ backgroundColor: color }}
                ></Box>
              </MenuItem>
            ))}
          </Select>
        </RAMFormControl>

        {/* TABLE ROW HEIGHT */}
        <RAMTextField
          type="number"
          label="Row Height"
          defaultValue={settings.row_height}
          onBlur={(event) =>
            handleSettingChange("row_height", Number(event.target.value))
          }
          size="small"
          fullWidth
        />

        {/* TABLE WIDTH */}
        <RAMTextField
          type="number"
          label="Table Width"
          defaultValue={settings.table_width}
          onBlur={(event) =>
            handleSettingChange("table_width", Number(event.target.value))
          }
          size="small"
          fullWidth
        />
      </Box>

      <Box width="100%" display="flex" gap="10px" justifyContent="space-around">
        {/* TABLE FONT FAMILY */}
        <RAMFormControl size="small" fullWidth>
          <InputLabel>Font Family</InputLabel>
          <Select
            value={settings.font_family}
            onChange={(event) =>
              handleSettingChange("font_family", event.target.value as string)
            }
            label="Font Family"
          >
            {[theme.font_family].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </RAMFormControl>

        {/* TABLE FONT SIZE */}
        <RAMTextField
          type="number"
          label="Font Size"
          defaultValue={settings.font_size}
          inputRef={fontSizeRef}
          onBlur={(event) =>
            handleSettingChange("font_size", Number(event.target.value))
          }
          style={{ width: "100px" }}
          size="small"
        />
      </Box>
    </Box>
  );
};

export default TableSettings;
