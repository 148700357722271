import React from "react";

interface QuickActionProps {
  name: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const QuickAction: React.FC<QuickActionProps> = ({}) => {
  return <></>;
};

export default QuickAction;
