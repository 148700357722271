import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Chip,
  styled,
  Tooltip,
  IconButton,
  MenuItem,
  ListItemText,
  Menu,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Select,
  CircularProgress,
  FormControl,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Add from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UpdateIcon from "@mui/icons-material/Update";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useDataContext } from "../../../../contexts/DataContext";
import { alpha } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  listBins,
  renameFile,
  updateFile,
  uploadFile,
  validateFile,
} from "../../../../utils/requestUtils";
import { useAuth0 } from "@auth0/auth0-react";
import ValidatedUploadField from "../../../common/ValidatedUploadField";
import { useUserReport } from "../../../../../../common/contexts/UserReportContext";
import { pingSlack } from "../../../../../../common/utils/pingSlack";

const InfoItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.5),
  backgroundColor: "#312c33",
  "& > svg": {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    flexShrink: 0,
  },
}));

const TruncatedTypography = styled(Typography)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const TransparentChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "transparent",
  border: `1px solid ${alpha(theme.palette.common.white, 0.3)}`,
  color: theme.palette.text.primary,
  height: "unset",
}));

interface FilePanelProps {}

const FilesPanel: React.FC<FilePanelProps> = () => {
  const { filesMetadata, setFilesMetadata } = useDataContext();
  const { openErrorPrompt } = useUserReport();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [renameDialogOpen, setRenameDialogOpen] = useState<boolean>(false);
  const [updateFileDialogOpen, setUpdateFileDialogOpen] =
    useState<boolean>(false);
  const [fileToModify, setFileToModify] = useState<string | null>(null); // file to modify in dialog
  const [newFileDialogOpen, setNewFileDialogOpen] = useState<boolean>(false);

  // rename file dialog states
  const [renameFileName, setRenameFileName] = useState<string>("");
  const [isRenameLoading, setIsRenameLoading] = useState<boolean>(false);

  // new file dialog states
  const [newFileName, setNewFileName] = useState<string>("");
  const [newFileBin, setNewFileBin] = useState<string>("");
  const [binOptions, setBinOptions] = useState<string[]>([]);

  const [expandedFilePath, setExpandedFilePath] = useState<string | null>(null); // file with details expanded
  const [expandedValidationErrors, setExpandedValidationErrors] = useState<
    string | null
  >(null);

  const { getAccessTokenSilently, user } = useAuth0();

  // Settings Menu Handers - Menu that appears when settings icon is selected
  const handleSettingsMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    path: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setFileToModify(path);
  };

  const handleSettingsMenuClose = () => {
    setAnchorEl(null);
  };

  // Rename File Handlers
  const handleRenameDialogOpen = () => {
    setRenameFileName(fileToModify ? filesMetadata[fileToModify].name : "");
    setRenameDialogOpen(true);
    handleSettingsMenuClose();
  };

  const handleRenameDialogClose = () => {
    setRenameDialogOpen(false);
    setFileToModify(null);
    setRenameFileName("");
    setIsRenameLoading(false);
  };

  const handleRenameSubmit = async (file: string) => {
    setIsRenameLoading(true);

    const token = await getAccessTokenSilently();
    const response = await renameFile(file, renameFileName, token);
    if (response.isSuccess) {
      setFilesMetadata(response.body);
    }
    handleRenameDialogClose();
  };

  // Update file handlers
  const handleUpdateFileDialogOpen = () => {
    setUpdateFileDialogOpen(true);
    handleSettingsMenuClose();
  };

  const handleUpdateDialogClose = () => {
    setUpdateFileDialogOpen(false);
    setFileToModify(null);
  };

  const handleValidateFile = async (
    file: File,
  ): Promise<[boolean, string[]]> => {
    const token = await getAccessTokenSilently();
    const response = await validateFile(file, token);

    if (response.isSuccess) {
      return [true, []];
    } else {
      // Check if the error is a validation error (status 422)
      if (response.body.status === 422) {
        const responseBody = await response.body.json();
        const errors = responseBody.detail.errors;
        return [false, errors];
      } else {
        const responseText = await response.body.text();
        await pingSlack(
          `ERROR OCCURED\nOccured for: ${user?.name || "Unknown"}\nDescription: an unexpected error occured when validating a file\n${responseText}`,
          token,
        );
        return [false, [`An unknown error occurred: ${response.body.status}`]];
      }
    }
  };

  const handleUpdateFileSubmit = async (
    path: string,
    file: File,
  ): Promise<void> => {
    const token = await getAccessTokenSilently();
    const response = await updateFile(path, file, token);

    if (!response.isSuccess) {
      const error = await response.body.text();
      const warning = "An error occured while uploading the file.";
      const slackMessage = `The Plotting App Failed to update a file through the FilesPanel component: \n ${error}`;
      openErrorPrompt(warning, slackMessage);
    }
  };

  // New File Handlers
  const handleNewFileDialogOpen = () => {
    setNewFileDialogOpen(true);
    getFileBins();
  };

  const handleNewDialogClose = () => {
    setNewFileDialogOpen(false);
    setNewFileBin("");
    setNewFileName("");
  };

  const handleNewFileSubmit = async (
    file: File,
    fileName: string,
    binName: string,
  ) => {
    const token = await getAccessTokenSilently();
    const response = await uploadFile(file, fileName, binName, token);
    if (response.isSuccess) {
      const metadata = await response.body.json();
      setFilesMetadata(metadata);
    } else {
      const error = await response.body.text();
      const warning = "An error occured while uploading the file.";
      const slackMessage = `The Plotting App Failed to upload a new file through the FilesPanel component: \n ${error}`;
      openErrorPrompt(warning, slackMessage);
    }
  };

  const getFileBins = async () => {
    const token = await getAccessTokenSilently();
    const response = await listBins(token);
    const bins = await response.body.json();
    setBinOptions(bins);
  };

  // Expanded file handlers
  const handleFileClick = (path: string) => {
    setExpandedFilePath(expandedFilePath === path ? null : path);
  };
  const handleToggleValidationErrors = (path: string) => {
    setExpandedValidationErrors(
      expandedValidationErrors === path ? null : path,
    );
  };

  return (
    <Box height="100%" width="100%" padding="10px">
      {Object.entries(filesMetadata).map(([path, metadata]) => (
        <Box key={path} mb={1}>
          <Box
            onClick={() => handleFileClick(path)}
            sx={{
              p: 1,
              bgcolor: "#3b363d",
              borderRadius: 1,
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TruncatedTypography>{metadata.name}</TruncatedTypography>
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleSettingsMenuOpen(event, path);
              }}
              sx={{ color: "inherit" }}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
          </Box>
          {expandedFilePath === path && (
            <Box
              sx={{
                mt: 1,
                p: 1,
                bgcolor: "#312c33",
                borderRadius: 1,
                boxShadow: "0 0 11px 3px #00000019",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                sx={{
                  p: 1,
                  bgcolor: "#3b363d",
                  borderRadius: 1,
                  mb: 1,
                }}
              >
                <TruncatedTypography variant="body2">
                  {path}
                </TruncatedTypography>
                <Tooltip title="Bin">
                  <TransparentChip
                    label={metadata.bin.toUpperCase()}
                    size="medium"
                  />
                </Tooltip>
              </Box>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <InfoItem>
                    <FolderIcon />
                    <Tooltip title="Display Name">
                      <TruncatedTypography variant="body2">
                        {metadata.name}
                      </TruncatedTypography>
                    </Tooltip>
                  </InfoItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InfoItem>
                    {metadata.isValid ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <ErrorIcon color="error" />
                    )}
                    <Tooltip title="Validity">
                      <Typography variant="body2">
                        {metadata.isValid ? "Valid" : "Invalid"}
                      </Typography>
                    </Tooltip>
                  </InfoItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InfoItem>
                    <CalendarTodayIcon />
                    <Tooltip title="Date Range">
                      <TruncatedTypography variant="body2">
                        {metadata.dateRange}
                      </TruncatedTypography>
                    </Tooltip>
                  </InfoItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InfoItem>
                    <UpdateIcon />
                    <Tooltip title="Last Updated">
                      <TruncatedTypography variant="body2">
                        {metadata.last_updated}
                      </TruncatedTypography>
                    </Tooltip>
                  </InfoItem>
                </Grid>
                {!metadata.isValid && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        bgcolor: "rgba(0, 0, 0, 0.2)",
                        borderRadius: 1,
                        overflow: "hidden",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1) inset",
                      }}
                    >
                      <InfoItem
                        onClick={() => handleToggleValidationErrors(path)}
                        sx={{
                          cursor: "pointer",
                          bgcolor: "#3b363d",
                        }}
                      >
                        <Typography variant="body2">
                          Validation Errors
                        </Typography>
                        {expandedValidationErrors === path ? (
                          <ExpandLessIcon sx={{ ml: "auto" }} />
                        ) : (
                          <ExpandMoreIcon sx={{ ml: "auto" }} />
                        )}
                      </InfoItem>
                      {expandedValidationErrors === path &&
                        metadata.validation_errors && (
                          <Box sx={{ mt: 1, pl: 1, pb: 1, pr: 2 }}>
                            {metadata.validation_errors.map((error, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                color="error"
                                sx={{
                                  mb: 0.5,
                                  pl: 2,
                                  borderLeft: "2px solid",
                                  borderColor: "error.main",
                                }}
                              >
                                {error}
                              </Typography>
                            ))}
                          </Box>
                        )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      ))}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSettingsMenuClose}
      >
        <MenuItem onClick={() => handleRenameDialogOpen()}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleUpdateFileDialogOpen()}>
          <ListItemIcon>
            <CloudUploadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Reupload</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog open={renameDialogOpen} onClose={handleRenameDialogClose}>
        <DialogTitle>Rename File</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New File Name"
            type="text"
            fullWidth
            value={renameFileName}
            onChange={(e) => setRenameFileName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameDialogClose}>Cancel</Button>
          <Button
            onClick={() => handleRenameSubmit(fileToModify!)}
            disabled={isRenameLoading}
            startIcon={isRenameLoading ? <CircularProgress size={20} /> : null}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload File Dialog */}
      <Dialog open={updateFileDialogOpen} onClose={handleUpdateDialogClose}>
        <Box sx={{ width: "300px" }}>
          <ValidatedUploadField
            validateFile={handleValidateFile}
            uploadFile={(file) => handleUpdateFileSubmit(fileToModify!, file)}
          />
        </Box>
      </Dialog>

      {/* New File Dialog */}
      <Dialog open={newFileDialogOpen} onClose={handleNewDialogClose}>
        <Box sx={{ width: "300px" }}>
          <Box display="flex" gap={2} sx={{ p: 2 }}>
            <TextField
              label="Name"
              value={newFileName}
              onChange={(e) => setNewFileName(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="file-bin-label">File Bin</InputLabel>
              <Select
                labelId="file-bin-label"
                label="File Bin"
                value={newFileBin}
                onChange={(e) => setNewFileBin(e.target.value as string)}
              >
                {binOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <ValidatedUploadField
            disabled={!newFileName.length || !newFileBin}
            validateFile={handleValidateFile}
            uploadFile={(file) =>
              handleNewFileSubmit(file, newFileName, newFileBin!)
            }
          />
        </Box>
      </Dialog>

      <Box
        sx={{
          position: "sticky",
          bottom: 16,
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 2,
        }}
      >
        <Tooltip title="Add new file">
          <IconButton
            onClick={handleNewFileDialogOpen}
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: "black",
              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.dark,
              },
            }}
          >
            <Add />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default FilesPanel;
