import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import FileInput from "../components/FileInput";
import FileDownload from "../components/FileDownload";
import ResponsiveAppBar from "../../../common/components/ResponsiveAppBar";
import TextInput from "../components/TextInput";
import { useAuth0 } from "@auth0/auth0-react";
import { getSummaryDownloadLink } from "../utils/requestUtils";
import { useUserReport } from "../../../common/contexts/UserReportContext";

const PodcastPostScreen = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { openErrorPrompt } = useUserReport();
  const theme = useTheme();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [textInputValue, setTextInputValue] = useState("");
  const [downloadName, setDownloadName] = useState(null);

  useEffect(() => {
    if (uploadedFiles.length > 0 && textInputValue.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [uploadedFiles, textInputValue]);

  const handleTextInputValueChange = (event) => {
    setTextInputValue(event.target.value);
  };

  const onSubmit = async () => {
    setDownloadUrl(null);
    setSubmitLoading(true);
    const token = await getAccessTokenSilently();
    const response = await getSummaryDownloadLink(
      uploadedFiles,
      textInputValue,
      token,
    );
    if (response.isSuccess) {
      const url = URL.createObjectURL(response.body);
      setDownloadUrl(url);
      setDownloadName(textInputValue + " Summary.docx");
      setSubmitLoading(false);
    } else {
      const warning = "An error occured while creating the podcast summary.";
      const slackMessage = `The Podcast Post App failed to generate a summary.`;
      openErrorPrompt(warning, slackMessage);
    }
  };

  return (
    <div>
      <ResponsiveAppBar activePage={"Post"} />
      <div
        style={{
          width: "80%",
          margin: "20px auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Paper
              elevation={1}
              sx={{
                padding: "15px 30px",
                height: "800px",
                overflow: "auto",
                backgroundColor: theme.palette.surfaces.darker,
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.scrollbar.thumb,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: theme.palette.scrollbar.track,
                },
              }}
            >
              <Stack spacing={3}>
                <Box
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: theme.palette.surfaces.lightish,
                  }}
                >
                  <h1 style={{ color: theme.palette.text.gentleWhite }}>
                    Inputs
                  </h1>
                </Box>

                <FileInput
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  dragHeight="100px"
                  listHeight="100px"
                />
                <TextInput
                  label="Podcast Title"
                  value={textInputValue}
                  onChange={handleTextInputValueChange}
                />
                <LoadingButton
                  variant="outlined"
                  onClick={onSubmit}
                  loading={submitLoading}
                  disabled={submitDisabled}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper
              variant="outlined"
              sx={{
                padding: "15px 30px",
                height: "800px",
                overflow: "auto",
                backgroundColor: theme.palette.surfaces.darker,
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.scrollbar.thumb,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: theme.palette.scrollbar.track,
                },
                position: "relative",
              }}
            >
              <Stack spacing={3}>
                <Box
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: theme.palette.surfaces.lightish,
                  }}
                >
                  <h1 style={{ color: theme.palette.text.gentleWhite }}>
                    Output
                  </h1>
                </Box>
                {downloadUrl && (
                  <FileDownload url={downloadUrl} fileName={downloadName} />
                )}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PodcastPostScreen;
