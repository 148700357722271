import React from "react";
import {
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DialogProvider, useDialogContext } from "./DialogContext";
import { Theme } from "@mui/material/styles";

interface QuickActionsDialogProps {
  children: React.ReactNode;
  position?: {
    vertical: "top" | "bottom";
    horizontal: "left" | "right";
  };
  direction?: "left" | "right" | "up" | "down";
  size?: "small" | "medium" | "large";
  spacing?: number;
  tooltipOpen?: boolean;
  tooltipPlacement?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
}

const QuickActionsDialogContent: React.FC<QuickActionsDialogProps> = ({
  children,
  position = { vertical: "bottom", horizontal: "right" },
  direction = "up",
  spacing = 16,
  tooltipOpen = false,
  tooltipPlacement = "left",
  size = "large",
}) => {
  const { dialogOpen, dialogContent, dialogName, closeDialog, openDialog } =
    useDialogContext();

  const actions = React.Children.toArray(children);

  const getSpeedDialStyles = (theme: Theme) => {
    const sizeStyles = {
      small: {
        "& .MuiSpeedDial-fab": {
          width: 40,
          height: 40,
        },
        "& .MuiSpeedDialIcon-icon": {
          fontSize: "1.2rem",
          // Adjust positioning for small size
          "& .MuiSvgIcon-root": {
            fontSize: "1.2rem",
          },
        },
        // Center the icon for small size
        "& .MuiSpeedDialIcon-root": {
          height: "24px",
          width: "24px",
        },
      },
      medium: {
        "& .MuiSpeedDial-fab": { width: 56, height: 56 },
        "& .MuiSpeedDialIcon-icon": { fontSize: "1.5rem" },
      },
      large: {
        "& .MuiSpeedDial-fab": { width: 72, height: 72 },
        "& .MuiSpeedDialIcon-icon": { fontSize: "1.8rem" },
      },
    };

    return {
      position: "absolute",
      [position.vertical]: spacing,
      [position.horizontal]: spacing,
      pointerEvents: "auto",
      ...sizeStyles[size],

      "& .MuiSpeedDialIcon-root": {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    };
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "30px",
        }}
      >
        <SpeedDial
          ariaLabel="Quick Actions SpeedDial"
          sx={(theme) => getSpeedDialStyles(theme)}
          icon={<SpeedDialIcon />}
          direction={direction}
        >
          {actions.map((child, index) => {
            if (React.isValidElement(child) && !child.props.disabled) {
              return (
                <SpeedDialAction
                  key={index}
                  icon={child.props.icon}
                  tooltipTitle={child.props.name}
                  tooltipOpen={tooltipOpen}
                  tooltipPlacement={tooltipPlacement}
                  onClick={() => {
                    if (child.props.children) {
                      openDialog(child.props.children, child.props.name);
                    } else if (child.props.onClick) {
                      child.props.onClick();
                    }
                  }}
                />
              );
            }
            return null;
          })}
        </SpeedDial>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {
            overflowY: "visible",
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: "background.paper" }}>
          <Box>
            {dialogName || "Quick Action"}
            <IconButton
              aria-label="close"
              onClick={closeDialog}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon sx={{ fontSize: "15pt" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "background.paper" }}>
          <Box sx={{ pt: "5px" }}>{dialogContent}</Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const QuickActionsDialog: React.FC<QuickActionsDialogProps> = (props) => (
  <DialogProvider>
    <QuickActionsDialogContent {...props} />
  </DialogProvider>
);

export default QuickActionsDialog;
