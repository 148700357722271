import React from "react";
import FeedbackForm from "../common/components/FeedbackForm";
import { useAuth0 } from "@auth0/auth0-react";
import { pingSlack } from "../common/utils/pingSlack";
import { Box } from "@mui/material";

const TestReportBug: React.FC = () => {
  const { getAccessTokenSilently, user } = useAuth0();

  const handlePingSlack = async (message: string) => {
    const token = await getAccessTokenSilently();
    const slackMessage = `
BUG REPORTED - PLOTTING APP

Reported by: ${user?.name || "Unknown"}
Description: ${message}
    `.trim();
    const response = await pingSlack(slackMessage, token);
    if (response.isSuccess) {
      alert("message sent to slack");
    } else {
      alert("failed to send");
    }
  };

  const handleOnClose = () => {
    alert("user tried to close");
  };

  return (
    <Box sx={{ p: 2 }}>
      <FeedbackForm
        onSubmit={handlePingSlack}
        title="Report a Bug"
        description="Looks like something went wrong. Please tell us what happened:"
        submitButtonText="Submit Bug Report"
        placeholder="Describe the bug you encountered..."
        onClose={handleOnClose}
      />
    </Box>
  );
};

export default TestReportBug;
