import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import RamLogo from "./RAMLogo";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { appConfigs } from "../../app_configs";

const NavButton = styled(Button)(({ theme }) => ({
  color: "#dcdddf",
  minWidth: "16px",
  padding: "0 16px",
  backgroundColor: "transparent",
  border: "none",
  textTransform: "none",
  position: "relative",
  "&:hover": {
    backgroundColor: "transparent",
    color: "white",
    "&::after": {
      transform: "scaleX(1)",
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 4,
    left: 0,
    width: "100%",
    height: "1px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    transform: "scaleX(0)",
    transition: "transform 0.3s ease-in-out",
  },
}));

function ResponsiveAppBar({ activePage }) {
  const {
    logout,
    user,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const history = useHistory();
  const [userScopes, setUserScopes] = useState([]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    handleCloseUserMenu();
  };

  useEffect(() => {
    const getUserScopes = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const decodedToken = JSON.parse(atob(token.split(".")[1]));
          setUserScopes(decodedToken.permissions || []);
        } catch (error) {
          console.error("Error getting user permissions:", error);
        }
      }
    };

    getUserScopes();
  }, [isAuthenticated, getAccessTokenSilently]);

  const hasRequiredScope = (requiredScopes) => {
    if (!requiredScopes || requiredScopes.length === 0) return true;
    return requiredScopes.every(scope => userScopes.includes(scope));
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "transparent" }}>
      <Toolbar
        disableGutters
        sx={{
          alignItems: "flex-end",
          justifyContent: "center",
          backgroundColor: "transparent",
          "@media (min-width: 600px)": {
            minHeight: "unset",
          },
        }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          height="55px"
          maxWidth="80rem"
        >
          {/* Left Content */}
          <Box display="flex">
            <RamLogo width={"100px"} sx={{ marginRight: "50px" }} />

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <NavButton
                key="Home"
                onClick={() => history.push("/")}
                active={activePage === "Home"}
              >
                Home
              </NavButton>

              {appConfigs.map((app) => (
                <NavButton
                  key={app.name}
                  onClick={() => hasRequiredScope(app.requiredScopes) && history.push(`/${app.path}`)}
                  active={activePage === app.name}
                  disabled={!hasRequiredScope(app.requiredScopes)}
                >
                  {app.name}
                </NavButton>
              ))}
            </Box>
          </Box>

          {/* Right Content */}
          <Box width="100px" display="flex" flexDirection="row-reverse">
            <IconButton onClick={handleOpenUserMenu} height="fit-content">
              <Avatar
                alt={user?.name || "User"}
                src={user?.picture}
                sx={{
                  width: 25,
                  height: 25,
                }}
              >
                {!user?.picture && user?.name
                  ? user.name.charAt(0).toUpperCase()
                  : null}
              </Avatar>
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {isAuthenticated ? (
                <MenuItem key="logout" onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              ) : (
                <MenuItem key="login" onClick={handleLogin}>
                  <Typography textAlign="center">Login</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default ResponsiveAppBar;
